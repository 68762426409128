import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { ChoiceButton } from 'components/ChoiceButton'
import { Header } from 'components/Header'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const Gender: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user, setUser } = useUser()

  const onButtonClick = (gender: string) => {
    setUser({ ...user, gender })
    window.location.href = nextRoute
  }

  return (
    <div className="default-container flex bg-gender bg-center">
      <div className="flex h-full w-full flex-col self-center overflow-auto pt-8 pb-4">
        <Header showLogin color="white" />

        <div className="flex h-full flex-col justify-between px-7 pt-14">
          <div className="flex flex-col self-center">
            <Heading text={t('gender.headline_1')} />

            <div className="mt-9">
              <Heading text={t('gender.headline_2')} />
            </div>
          </div>

          <div className="flex w-full flex-col self-center lg:w-5/6 xl:w-5/12">
            <ButtonWrapper small>
              <ChoiceButton
                onClick={() => onButtonClick('f')}
                value={t('gender.gender_1')}
                id="female"
              />
              <ChoiceButton
                onClick={() => onButtonClick('m')}
                value={t('gender.gender_2')}
                id="male"
              />
              <ChoiceButton
                onClick={() => onButtonClick('other')}
                value={t('gender.gender_3')}
                id="non_binary"
              />

              <div className="mt-2 flex flex-row justify-center gap-2">
                <p className="text-sm text-white opacity-30">
                  Terms & Conditions · Imprint
                </p>
              </div>
            </ButtonWrapper>
          </div>
        </div>
      </div>
    </div>
  )
}
