import React from 'react'

interface Props {
  text: string
  textColor?: string
  centered?: boolean | undefined
}

export const Heading: React.FC<Props> = ({ text, textColor, centered }) => {
  return (
    <h1
      className={`${centered ? 'text-center' : ''} font-title font-bold text-${
        textColor || 'white'
      } text-2xl xl:mt-0 xl:text-center`}>
      {text}
    </h1>
  )
}
