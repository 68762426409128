import React from 'react'

interface Props {
  text: string
}

export const Question: React.FC<Props> = ({ text }) => {
  return (
    <>
      <div className="flex cursor-pointer justify-between">
        <div className="order-last text-ar-green">
          <img
            src="/images/arrow_down.png"
            className="h-5 w-5"
            alt="arrow_down"
          />
        </div>
        <p className="text-left font-title text-xs text-black">{text}</p>
      </div>
    </>
  )
}
