import { useTranslation } from 'react-i18next'
import { ReactComponent as Logo } from 'icons/logo.svg'

interface Props {}

export const CancellationRequestSuccess: React.FC<Props> = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  return (
    <>
      <div className="mx-auto flex h-screen flex-col px-8 pt-9 lg:w-5/6 xl:w-5/12">
        <div className="flex flex-row gap-2 self-center">
          <Logo height="30" width="35" fill="black" />
          <p className="font-title text-2xl font-bold tracking-wide">
            asana rebel
          </p>
        </div>

        <div className="mt-8 flex h-full flex-col justify-center">
          <img
            alt="Green Checkmark Circle"
            src="/images/green_checkmark_circle.png"
            className="w-18 self-center"
          />
          <p className="mt-8 self-center font-title text-3xl font-bold">
            {t('cancellation_request_success.headline')}
          </p>
          <p className="mt-4 self-center font-body text-sm">
            {t('cancellation_request_success.body')}
          </p>
        </div>
      </div>
    </>
  )
}
