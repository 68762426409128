import { createContext, useContext, Dispatch, SetStateAction } from 'react'

import { Plan } from 'pages/Plans'

interface PlanState {
  plan: Plan | undefined
  setPlan: Dispatch<SetStateAction<Plan | undefined>>
}

const PlanContext = createContext({} as PlanState)

export const usePlan = () => useContext(PlanContext)

export default PlanContext
