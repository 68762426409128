import React, { LegacyRef, ChangeEvent } from 'react'

type InputType =
  | 'tel'
  | 'numeric'
  | 'search'
  | 'text'
  | 'email'
  | 'url'
  | 'none'
  | 'decimal'

interface Props {
  placeholder: string
  id: string
  type?: string
  inputMode?: InputType
  value?: string
  onChange?: (arg0: ChangeEvent<HTMLInputElement>) => void
  onFocus?: () => void
  darkMode?: boolean
}

const Input = React.forwardRef(
  (
    {
      placeholder,
      type = 'tel',
      inputMode = 'numeric',
      value = '',
      onChange,
      onFocus,
      darkMode = true,
      id,
    }: Props,
    ref: LegacyRef<HTMLInputElement>,
  ) => (
    <input
      className={`placeholder-text-opacity-50 w-full rounded-md p-9 text-center font-link
                 text-xl placeholder-ar-light-gray focus:outline-none xxsm:p-4
                 ${darkMode ? 'bg-white' : 'bg-ar-light-gray bg-opacity-10'}`}
      placeholder={placeholder}
      type={type}
      id={id}
      inputMode={inputMode}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      ref={ref}
    />
  ),
)

Input.displayName = 'Input'

export default Input
