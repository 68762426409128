import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { Heading } from 'components/Heading'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'route-paths'

interface Props {}

const APPLE_APP_STORE_URL =
  'https://apps.apple.com/us/app/asana-rebel-get-in-shape/id1067860796'
const GOOGLE_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.asanayoga.asanarebel'

export const ThankYou: React.FC<Props> = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const [checkboxChecked, setCheckboxChecked] = useState(false)

  const { user } = useUser()
  const navigate = useNavigate()

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent)
  }

  const isAndroid = () => {
    return /Android/i.test(navigator.userAgent)
  }

  const onClickDownload = () => {
    if (isIOS()) {
      window.open(APPLE_APP_STORE_URL, '_blank')
    } else if (isAndroid()) {
      window.open(GOOGLE_PLAY_STORE_URL, '_blank')
    } else {
      window.open(APPLE_APP_STORE_URL, '_blank')
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!user.subscription) {
      navigate(RoutePaths.Checkout, { replace: true })
    }
  }, [user.subscription, navigate])

  if (!user.subscription) {
    return null
  }

  return (
    <div className="default-container flex flex-col">
      <div className="no-scrollbar flex h-full flex-col self-center overflow-auto px-7 pt-7 lg:w-5/6 xl:w-7/12">
        <Heading text={t('thank_you.welcome')} textColor="black" centered />
        <div className="mt-3 flex h-full flex-col pb-96 text-center font-body text-sm text-ar-dark-gray">
          <p
            dangerouslySetInnerHTML={{
              __html: t('thank_you.thank_you', {
                interpolation: { escapeValue: false },
              }),
            }}
          />

          <div className="mt-7 flex h-8 w-8 self-center rounded-full bg-black">
            <p className="w-full self-center text-center font-title text-lg text-white">
              1
            </p>
          </div>

          <p className="mt-5 font-body">{t('thank_you.download')}</p>

          <div className="mt-6 flex h-8 w-8 self-center rounded-full bg-black">
            <p className="w-full self-center text-center font-title text-lg text-white">
              2
            </p>
          </div>

          <p
            className="mt-5 font-body"
            dangerouslySetInnerHTML={{
              __html: t('thank_you.sign_in', {
                interpolation: { escapeValue: false },
                email: user.email,
              }),
            }}
          />

          <p className="mt-5 text-3xl">❗️</p>
          <p
            className="mt-1 font-body"
            dangerouslySetInnerHTML={{
              __html: t('thank_you.do_not_start', {
                interpolation: { escapeValue: false },
                email: user.email,
              }),
            }}
          />
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 flex flex-col border-t border-ar-light-gray border-opacity-10 bg-white px-7 pt-2 pb-8">
        <div className="mx-auto flex flex-row space-x-2 self-center pt-6">
          <div className="relative flex h-5 w-5">
            <input
              className={`peer h-full w-full shrink-0 cursor-pointer appearance-none self-center rounded border border-neutral-400 focus:outline-none focus:ring-1 focus:ring-blue-100 focus:ring-offset-0 ${
                checkboxChecked ? 'border-0 bg-ar-green' : 'bg-neutral-300'
              }`}
              type="checkbox"
              onClick={() => setCheckboxChecked(!checkboxChecked)}
            />

            {checkboxChecked && (
              <p
                className="sell-center absolute inset-0 z-10 flex items-center justify-center font-body text-base text-white"
                onClick={() => setCheckboxChecked(!checkboxChecked)}>
                ✔
              </p>
            )}
          </div>
          <p className="self-center text-center font-body text-sm">
            {t('thank_you.checkbox')}
          </p>
        </div>
        <div className="mx-8 mt-4 xl:mx-36">
          <button
            type="button"
            onClick={onClickDownload}
            disabled={!checkboxChecked}
            className={`text-md m-auto block w-full rounded-lg bg-ar-green p-5 text-center font-link uppercase text-white
                      hover:bg-opacity-90 focus:outline-none lg:w-5/6 lg:p-4 xl:w-5/12 xxsm:p-3
                      ${!checkboxChecked ? 'bg-opacity-50' : ''}`}>
            {t('thank_you.download')}
          </button>
        </div>
      </div>
    </div>
  )
}
