import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { ChoiceButton } from 'components/ChoiceButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const Pace: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()
  const { user } = useUser()

  const genderBasedBackground =
    user.gender === 'm' ? 'bg-pace-male bg-center' : 'bg-pace-female bg-center'

  const onButtonClick = () => {
    navigate(nextRoute)
  }

  return (
    <div className={`default-container ${genderBasedBackground} flex flex-col`}>
      <div className="flex h-full flex-col justify-between self-center overflow-auto px-7 pb-10 pt-7 lg:w-5/6 xl:w-5/12">
        <div className="flex flex-col gap-y-2">
          <Heading text={t('pace.headline')} />
          <p className="font-body text-sm text-white xl:text-center">
            {t('pace.subheadline')}
          </p>
        </div>

        <ButtonWrapper>
          <ChoiceButton
            onClick={onButtonClick}
            value={t('pace.pace_1')}
            id="pace_1"
          />
          <ChoiceButton
            onClick={onButtonClick}
            value={t('pace.pace_2')}
            id="pace_2"
          />
          <ChoiceButton
            onClick={onButtonClick}
            value={t('pace.pace_3')}
            id="pace_3"
          />
        </ButtonWrapper>
      </div>
    </div>
  )
}
