import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import useApi from 'hooks/useApi'
import { useKeyPress } from 'hooks/useKeyPress'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Header } from 'components/Header'
import { Heading } from 'components/Heading'
import Input from 'components/Input'
import { RoutePaths } from 'route-paths'

interface Props {
  locale: string
}

export const PasswordReset: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const { resetPassword } = useApi(locale)

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const email = searchParams.get('email')

  const onResetPassword = async () => {
    setLoading(true)

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'reset_password',
    })

    if (!token || !password) {
      return
    }

    try {
      const { errors } = await resetPassword({ token, password })

      if (!errors) {
        toast.success(t('reset_password.updated_successfully'))
        setTimeout(() => {
          navigate(RoutePaths.Login)
        }, 2500)
      } else {
        setLoading(false)
        Sentry.captureException(
          'Unexpected Error during passoword reset: Auth Token Missing.',
        )
        toast.error(t('errors.unexpected_error'))
      }
    } catch (error) {
      Sentry.captureException(error)
      setLoading(false)
      toast.error(t('errors.unexpected_error'))
    }
  }

  useEffect(() => {
    if (!token || !email) {
      navigate(RoutePaths.Login)
    }
  }, [token, email, navigate])

  useKeyPress('ENTER', onResetPassword)

  return (
    <div className="pt-8">
      <Header color="black" />
      <div className="mx-auto flex flex-col justify-start px-8 pt-9 lg:w-5/6 xl:w-5/12">
        <Heading text={t('reset_password.title')} textColor="ar-dark-gray" />

        <div className="mt-8 mb-36 flex flex-col gap-3">
          <p>
            <strong>{t('email.placeholder')}:</strong> {email}
          </p>

          <Input
            id="password_input"
            placeholder={t('reset_password.new_password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            inputMode="text"
            darkMode={false}
          />
        </div>
      </div>
      <ToastContainer position="bottom-center" transition={Slide} />
      <ContinueButton
        onClick={onResetPassword}
        buttonText={t('reset_password.submit')}
        disabled={loading}
        disabledText={
          loading ? t('reset_password.submitting') : t('reset_password.submit')
        }></ContinueButton>
    </div>
  )
}
