import React, { useEffect, useRef } from 'react'
import { confetti } from 'dom-confetti'

interface Props {
  active: boolean
  className?: string
}

interface ConfettiConfig {
  angle: number
  spread: number
  startVelocity: number
  elementCount: number
  dragFriction: number
  duration: number
  stagger: number
  width: string
  height: string
  perspective: string
  colors: string[]
}

export const Confetti: React.FC<Props> = ({ active = false, className }) => {
  const ref = useRef<HTMLDivElement>(null)

  const config: ConfettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 5000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  }

  useEffect(() => {
    if (ref.current && active) {
      confetti(ref.current, config)
    }
  })

  return (
    <div className={className} style={{ position: 'relative' }} ref={ref} />
  )
}
