import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { ContinueButton } from 'components/ContinueButton'

interface Props {
  nextRoute: string
}

export const MeditationMind: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user } = useUser()

  const navigate = useNavigate()

  const onContinue = () => {
    navigate(nextRoute)
  }

  const genderBasedImage =
    user.gender === 'm' ? 'meditation-mind-male' : 'meditation-mind-female'

  return (
    <div className="flex flex-col">
      <div
        className={`relative h-[400px] bg-cover bg-center bg-${genderBasedImage}`}>
        <div className="flex w-full flex-col items-center pt-20 md:pt-10">
          <img
            src="/images/2_number.png"
            alt="Number 1"
            className="max-w-[10%]"
          />
          <p className="mt-2 font-title text-6xl text-white">
            {t('meditation_pages.mind.title')}
          </p>
        </div>
      </div>
      <div className="flex flex-col px-5 pt-24">
        <p
          className="text-center font-body text-sm text-ar-dark-gray"
          dangerouslySetInnerHTML={{
            __html: t('meditation_pages.mind.text', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </div>

      <ContinueButton onClick={onContinue} />
    </div>
  )
}
