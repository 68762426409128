import { PlanBoxLoading } from './PlanBoxLoading'

interface Props {}

// this whole component needs splitting into functions and smaller components
export const PlanBoxesLoading: React.FC<Props> = () => {
  return (
    <>
      <PlanBoxLoading />
    </>
  )
}
