import { Fragment } from 'react'
import { Transition, Dialog } from '@headlessui/react'

interface Props {
  onClose: () => void
  open: boolean
}

export const Modal: React.FC<Props> = ({ onClose, open, children }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
        as="div">
        <div className="flex min-h-screen w-full items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block w-[300px] transform overflow-hidden rounded-lg bg-ar-white align-bottom shadow-xl transition-all sm:my-8 sm:max-w-7xl sm:align-middle">
              <span
                className="absolute top-[3%] right-[8%] cursor-pointer font-link text-lg text-ar-green"
                onClick={onClose}>
                x
              </span>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
