import { useEffect } from 'react'
import { toast } from 'react-toastify'
import camelcaseKeys from 'camelcase-keys'
import { useSearchParams } from 'react-router-dom'
import useApi, { PlansResponse } from 'hooks/useApi'
import useLocalStorageState from 'use-local-storage-state'
import { Plan } from 'pages/Plans'

// use 'EUR' instead of 'USD' if you want to test it with paypal locally
// pointing it to recurly sandbox
const FALLBACK_CURRENCY = 'USD'
const LOCAL_STORAGE_ALL_PLANS_KEY = 'asanarebel.checkout.plans'
const LOCAL_STORAGE_SELECTED_PLAN_KEY = 'asanarebel.checkout.plan'
const LOCAL_STORAGE_CURRENCY_KEY = 'asanarebel.checkout.currency'
const LOCAL_STORAGE_COUNTRY_KEY = 'asanarebel.checkout.country'

export default function usePlanData(locale: string) {
  const { getPlans, getGeolocation } = useApi(locale)
  const [searchParams] = useSearchParams()
  const planId = searchParams.get('plan_id')

  const [selectedPlan, setSelectedPlan] = useLocalStorageState<Plan>(
    LOCAL_STORAGE_SELECTED_PLAN_KEY,
  )
  const [plans, setPlans] = useLocalStorageState<Plan[]>(
    LOCAL_STORAGE_ALL_PLANS_KEY,
  )
  const [country, setCountry] = useLocalStorageState<string | undefined>(
    LOCAL_STORAGE_COUNTRY_KEY,
  )
  const [currency, setCurrency] = useLocalStorageState<string>(
    LOCAL_STORAGE_CURRENCY_KEY,
    { defaultValue: FALLBACK_CURRENCY },
  )

  const getGeolocationData = async (plansResponse: PlansResponse) => {
    const geolocationResponse = await getGeolocation()

    const findCurrency = (apiCurrency: string) => {
      const allCurrencies = plansResponse.plans
        .map((plan) => {
          return plan.currencies.map((p) => p.currency)
        })
        .flat()

      return allCurrencies.find((c) => c === apiCurrency)
    }

    if (!geolocationResponse.error) {
      setCountry(geolocationResponse.country_alphabetical_code)

      const supportedCurrency = findCurrency(geolocationResponse.currency)

      if (supportedCurrency) {
        setCurrency(supportedCurrency)
      }
    } else {
      console.warn(geolocationResponse.error)
    }
  }

  useEffect(() => {
    if (!plans || !planId) {
      return
    }
    const planIdPresent = (plan: Plan) => plan.code === planId

    if (planId && plans.some(planIdPresent)) {
      setSelectedPlan(
        camelcaseKeys(plans.find(planIdPresent)!, {
          deep: true,
        }),
      )
    }
  }, [plans, planId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (plans) {
      return
    }

    const fetchData = async () => {
      const plansResponse = await getPlans()

      if (!plansResponse.error) {
        await getGeolocationData(plansResponse)

        setPlans(camelcaseKeys(plansResponse.plans, { deep: true }))
      } else {
        toast.error(plansResponse.error)
      }
    }

    fetchData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    plans,
    country,
    currency,
    selectedPlan,
    setSelectedPlan,
  }
}
