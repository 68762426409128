export const performTrack = ({
  platform,
  eventName,
  payload,
}: {
  platform: string
  eventName: string
  payload: object
}) => {
  const perform = async () => {
    const { Tracker } = await import('@asanarebel/asanarebel-tracker')
    const tracker = Tracker.init(process.env.REACT_APP_ASANA_REBEL_API_URL)
    tracker.perform({ platform, eventName, payload })
  }

  perform()
}
