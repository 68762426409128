import { useTranslation } from 'react-i18next'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import useSnowplow from 'hooks/useSnowplow'
import { useNavigate } from 'react-router-dom'
import { PreLoadImage } from 'components/PreLoadImage'
import { useUser } from 'context/UserContext'
import { RoutePaths } from 'route-paths'

export const FeedbackPersonalize: React.FC = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user } = useUser()
  const preloadBackground =
    user.gender === 'm' ? 'bg-goals-male' : 'bg-goals-female'

  const navigate = useNavigate()

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'feedback_personalize',
    })
    navigate(RoutePaths.Goals)
  }

  return (
    <>
      <PreLoadImage background={preloadBackground} />
      <div className="container mx-auto px-4 text-center">
        <div className="flex justify-center">
          <img
            src={'/images/feedback_personalize.png'}
            className="text-center"
            alt="Asana Rebel personalization feature screenshots"
          />
        </div>
        <div className="mt-6">
          <Heading
            textColor="ar-dark-gray "
            text={t('feedback_personalize.headline')}
          />

          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {t('feedback_personalize.subheadline')}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
