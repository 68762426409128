export default function useFacebookCookies() {
  const CookiesMap = new Map(
    decodeURIComponent(document.cookie)
      .split('; ')
      .map((v) => v.split('=')) as Iterable<[string, string]>,
  )

  const fbp = CookiesMap.get('_fbp')
  const fbc = CookiesMap.get('_fbc')

  return { fbp, fbc }
}
