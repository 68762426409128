import React from 'react'

interface Props {
  onClick: () => void
  id: string
  small?: boolean | undefined
  value: string
}

export const ChoiceButton: React.FC<Props> = ({
  onClick,
  value,
  id,
  small,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      id={id}
      className={`${small ? 'p-6' : 'p-7'}
                  text-md m-auto block w-full rounded-lg bg-white text-center font-link text-ar-dark-gray
                  hover:bg-opacity-90 focus:outline-none lg:p-7 xl:p-5`}>
      {value}
    </button>
  )
}
