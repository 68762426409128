import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import { ReactComponent as Logo } from 'icons/success.svg'

interface Props {
  nextRoute: string
}

export const FeedbackGoalWeight: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()

  const onButtonClick = () => {
    navigate(nextRoute)
  }

  return (
    <>
      <div className="flex h-full w-full flex-col items-center justify-center px-4 text-center">
        <Logo height="99" width="99" className="text-white" />

        <div className="mt-4">
          <Heading
            textColor="ar-dark-gray "
            text={t('feedback_goalweight.headline')}
          />

          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {t('feedback_goalweight.subheadline')}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
