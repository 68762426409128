import { useEffect } from 'react'
import useFacebookCookies from 'hooks/useFacebookCookies'
import { useUser } from 'context/UserContext'

const initializeFacebookPixel = () => {
  ;(function (f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js',
  )
}

const FacebookPixel = () => {
  const { fbp, fbc } = useFacebookCookies()
  const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID
  const { user } = useUser()

  useEffect(() => {
    // Initialize Facebook Pixel script
    initializeFacebookPixel()

    // Set up tracking parameters
    const matchingParams = {
      external_id: user.id,
      country: user.country,
      ge: user.gender,
      em: user.email,
      zp: user.zip,
      st: user.state,
      ct: user.city,
      fbp,
      fbc,
    }

    // Filter out null values
    const filteredParams = Object.fromEntries(
      Object.entries(matchingParams).filter(([_, value]) => value != null),
    )

    // Initialize tracking
    window.fbq('init', pixelId, filteredParams)
    window.fbq('track', 'PageView')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default FacebookPixel
