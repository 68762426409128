import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from 'icons/logo.svg'
import { RoutePaths } from 'route-paths'

interface Props {
  showLogin?: boolean | undefined
  color: string
}

export const Header: React.FC<Props> = ({ showLogin, color }) => {
  const navigate = useNavigate()

  return (
    <header className="grid w-full grid-cols-2 self-center px-8 lg:w-5/6">
      <Logo height="30" width="35" className="text-white" fill={color} />
      {showLogin && (
        <div className="z-10 cursor-pointer self-center text-right">
          <span
            onClick={() => {
              navigate(RoutePaths.Login)
            }}
            className={`font-link text-sm text-${color} self-center`}>
            Log In
          </span>
        </div>
      )}
    </header>
  )
}
