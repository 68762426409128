import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import useApi from 'hooks/useApi'
import { useUser } from 'context/UserContext'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { RoutePaths } from 'route-paths'

interface Props {
  locale: string
}

export const CancellationRequest: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user } = useUser()
  const [canSubmit, setCanSubmit] = useState(false)
  const [cancellationType, setCancellationType] = useState('')
  const [subject, setSubject] = useState('')
  const [description, setDescription] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [search] = useSearchParams()
  const { createCancellationRequest } = useApi(locale)
  const navigate = useNavigate()

  useEffect(() => {
    const typeFromParams = search.get('type')
    if (typeFromParams) {
      setCancellationType(typeFromParams)
    }
  }, [search])

  const updateCanSubmit = (description: string, subject: string) => {
    if (subject && description) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value)
    updateCanSubmit(description, e.target.value)
  }
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setDescription(e.target.value)
    updateCanSubmit(e.target.value, subject)
  }

  const handleSubmit = async () => {
    if (canSubmit && user.authToken && !isSubmitting) {
      setIsSubmitting(true)
      try {
        const response = await createCancellationRequest({
          authToken: user.authToken,
          type: cancellationType,
          subject: subject,
          description: description,
        })
        if (response.errors) {
          toast.error(response.errors)
          setCanSubmit(false)
        } else {
          navigate(RoutePaths.CancellationRequestSuccess)
        }
      } catch (error) {
        Sentry.captureException(error)
        toast.error(t('errors.unexpected_error'))
      }
    }
  }

  return (
    <>
      <div className="flex h-screen w-full flex-col px-5 py-20">
        <div className="xl:5/6 w-full self-center lg:w-3/4">
          <p className="font-title text-3xl font-bold">
            {t('cancellation_request.headline')}
          </p>

          <div className="mt-4 flex w-full flex-col justify-center font-body text-sm">
            <div className="flex w-full flex-col self-center md:w-3/4 xl:w-2/3">
              <div className="mt-5 flex flex-col">
                <p>{t('cancellation_request.reason')}</p>

                <div className="mt-2 w-full border border-gray-300 bg-gray-100 p-3">
                  <p>{t(`cancellation_request.${cancellationType}`)}</p>
                </div>
                {cancellationType === 'cancel' && (
                  <p className="mt-2 text-xs text-red-500">
                    {t('cancellation_request.not_a_refund_form')}
                  </p>
                )}
              </div>

              <div className="mt-5 flex flex-col">
                <p>Email</p>

                <div className="mt-2 w-full border border-gray-300 bg-gray-100 p-3">
                  <p>{user.email}</p>
                </div>
              </div>

              <div className="mt-5 flex flex-col">
                <p>
                  {t('cancellation_request.subject')}{' '}
                  <span className="text-red-600">*</span>
                </p>

                <input
                  type="text"
                  className="mt-2 border border-gray-300 p-3 first-letter:w-full focus:border-gray-400 focus:outline-none"
                  value={subject}
                  onChange={handleSubjectChange}
                  autoFocus
                />
              </div>

              <div className="mt-5 flex flex-col">
                <p>
                  {t('cancellation_request.description')}{' '}
                  <span className="text-red-600">*</span>
                </p>

                <textarea
                  className="mt-2 h-32 border border-gray-300 p-3 first-letter:w-full focus:border-gray-400 focus:outline-none"
                  value={description}
                  onChange={handleDescriptionChange}
                  autoFocus
                />

                <p className="mt-2 text-xs text-gray-500">
                  {t('cancellation_request.provide_detail')}
                </p>
              </div>

              <button
                className={`mt-12 px-20 py-2 text-white md:self-end md:py-3 ${
                  canSubmit && !isSubmitting
                    ? 'cursor-pointer bg-ar-green hover:bg-opacity-80'
                    : 'bg-neutral-400'
                }`}
                onClick={handleSubmit}
                disabled={!canSubmit || isSubmitting}>
                {t('cancellation_request.submit')}
              </button>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-center" transition={Slide} />
      </div>
    </>
  )
}
