import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import App from './components/App'
import i18n, { LOCALES } from './i18n'

const userLocale = window.navigator.language
const twoDigitLocale = userLocale.split('-')[0]
const locale = LOCALES.includes(twoDigitLocale) ? twoDigitLocale : 'en'

i18n.changeLanguage(locale)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <App locale={locale} />
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
