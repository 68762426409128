import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const FeedbackExperience: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()
  const { user } = useUser()

  const onButtonClick = () => {
    navigate(nextRoute)
  }

  return (
    <div className="default-container flex flex-col">
      <div className="flex h-full flex-col self-center overflow-auto px-7 pt-7 text-center lg:w-5/6">
        <Heading
          text={t('experience_yoga.headline')}
          textColor="ar-dark-gray"
        />

        <div className="flex h-1/2 w-full flex-col justify-center space-y-5">
          <div className="flex w-full flex-col self-center rounded-xl bg-white p-8 text-center shadow-feedback md:w-5/6 lg:w-5/6 xl:w-5/12">
            <p className="font-title text-sm text-ar-green">
              {t(`experience_yoga.option_${user.experienceYogaChoiceId}`)}
            </p>
          </div>

          <p className="mt-4 font-body text-sm text-ar-dark-gray">
            {t(`feedback_experience.feedback_${user.experienceYogaChoiceId}`)}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </div>
  )
}
