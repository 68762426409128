import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ContinueButton } from 'components/ContinueButton'

interface Props {
  nextRoute: string
}

export const MeditationBalance: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()

  const onContinue = () => {
    navigate(nextRoute)
  }

  return (
    <div className="default-container flex h-full flex-col justify-center">
      <p
        className="text-center font-body text-sm text-ar-dark-gray"
        dangerouslySetInnerHTML={{
          __html: t('meditation_pages.balance.text', {
            interpolation: { escapeValue: false },
          }),
        }}
      />
      <ContinueButton onClick={onContinue} />
    </div>
  )
}
