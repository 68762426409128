import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Confetti } from './Confetti'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const FeedbackGoalset: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()

  const onButtonClick = () => {
    navigate(nextRoute)
  }

  return (
    <>
      <div className="flex h-full w-full flex-col px-7 text-center">
        <div className="flex h-full flex-col justify-center">
          <div className="fixed left-1/2">
            <Confetti active />
          </div>
          <Heading textColor="ar-green" text={t('feedback_goalset.headline')} />
          <p className="mt-3 font-body text-sm text-ar-dark-gray xl:text-center">
            {t('feedback_goalset.subheadline')}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
