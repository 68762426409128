import { useEffect } from 'react'
import { RecurlyProvider, Elements } from '@recurly/react-recurly'
import { Form } from './Form'
import { useUser } from 'context/UserContext'
import { usePlan } from 'context/PlanContext'
import usePlanData from 'hooks/usePlanData'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Loading } from './Loading'
import { RoutePaths } from 'route-paths'

interface Props {
  locale: string
  recurlyPublicKey: string
  nextRoute: string
}

export const Checkout: React.FC<Props> = ({
  locale,
  recurlyPublicKey,
  nextRoute,
}) => {
  const { user } = useUser()
  const { plan } = usePlan()
  const [searchParams] = useSearchParams()
  const planId = searchParams.get('plan_id')
  const navigate = useNavigate()
  const { currency, country } = usePlanData(locale)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [user])

  useEffect(() => {
    if (!planId && !plan?.code) {
      navigate(RoutePaths.Plans, { replace: true })
    }
  }, [plan, planId, navigate])

  if (!plan?.code || !currency || !user.authToken || !user.email) {
    return <Loading />
  }

  return (
    <>
      <RecurlyProvider publicKey={recurlyPublicKey}>
        <Elements>
          <Form
            locale={locale}
            braintreeClientToken={process.env.REACT_APP_BRAINTREE_CLIENT_AUTH!}
            currency={currency}
            country={country}
            authToken={user.authToken}
            plan={plan}
            nextRoute={nextRoute}
          />
        </Elements>
      </RecurlyProvider>
    </>
  )
}
