import { useEffect, MutableRefObject } from 'react'

interface KeyCodes {
  [key: string]: string[]
}

const KEY_CODES: KeyCodes = {
  ESCAPE: ['Escape'],
  ENTER: ['Enter', 'NumpadEnter'],
  SPACE: ['Space'],
}

interface UseKeyPressOpts {
  ref?: MutableRefObject<HTMLElement>
}

export const useKeyPress = (
  keyType: string,
  onKey: () => void,
  opts: UseKeyPressOpts = {},
) => {
  useEffect(() => {
    const codes: string[] = KEY_CODES[keyType] ?? []

    const options = {
      preventDefault: false,
      shiftSkips: false,
      stopPropagation: false,
      ...opts,
    }

    // ignore null when doing this lazily
    if (options.ref && !options.ref.current) {
      if (!Object.keys(options.ref).includes('current')) {
        throw new Error('useKeyPress: invalid ref argument')
      }

      return undefined
    }
    const element = options.ref ? options.ref.current : window

    if (!element.addEventListener) {
      throw new Error('useKeyPress must be applied on a valid element')
    }

    const handleKeyPress = (event: any): void => {
      const code = event.code || event.key

      if (options.preventDefault) {
        event.preventDefault()
      }

      if (options.stopPropagation) {
        event.stopPropagation()

        return
      }

      if (!codes.includes(code) || (event.shiftKey && options.shiftSkips)) {
        return
      }
      onKey()
    }
    element.addEventListener('keyup', handleKeyPress)

    return () => {
      element.removeEventListener('keyup', handleKeyPress)
    }
  }, [onKey, keyType, opts])
}
