import { useState, useEffect, useRef, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { useKeyPress } from 'hooks/useKeyPress'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import Input from 'components/Input'

interface Props {
  nextRoute: string
}

export const Age: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-age-male-mobile md:bg-age-male bg-center'
      : 'bg-age-female bg-center'
  const navigate = useNavigate()
  const [canContinue, setCanContinue] = useState(false)
  const [year, setYear] = useState('')
  const yearInput = useRef(null)

  const onContinue = () => {
    if (!canContinue) {
      return
    }

    setUser({ ...user, dateOfBirth: `${year}` })
    setTimeout(() => {
      navigate(nextRoute)
    }, 100)
  }

  const onYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value

    if (/(^$|^\d{1,4}$)/.test(input)) {
      setYear(input)
    }
  }

  useKeyPress('ENTER', onContinue)

  useEffect(() => {
    setCanContinue(/^\d{4}$/.test(`${year}`))
  }, [year])

  return (
    <>
      <div
        className={`default-container ${genderBasedBackground} flex flex-col`}>
        <div className="flex h-full w-full flex-col justify-between self-center overflow-auto px-7 pt-7 lg:w-5/6 xl:w-5/12">
          <Heading text={t('age.headline')} />
          <p className="mt-2 text-left font-body text-sm text-white xl:text-center">
            {t('age.subheadline')}
          </p>
          <div className="w-100 z-0 mx-auto mt-5 mb-28 grid h-full w-full content-end">
            <Input
              placeholder="YYYY"
              value={year}
              id="year_input"
              onChange={onYearChange}
              ref={yearInput}
            />
          </div>
        </div>
      </div>
      <ContinueButton onClick={onContinue} disabled={!canContinue} />
    </>
  )
}
