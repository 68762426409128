import { useTranslation } from 'react-i18next'
import usePricing from 'hooks/usePricing'
import { CouponResponse } from 'hooks/useApi'
import { Plan } from 'pages/Plans'

interface Props {
  rampPrice: string
  currency: string
  plan: Plan
  coupon?: CouponResponse | null
}

export const OrderSummary: React.FC<Props> = ({
  currency,
  plan,
  coupon,
  rampPrice,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { trial, totalPrice, totalDiscountedPrice } = usePricing(
    plan,
    currency,
    coupon,
  )

  const fullPrice = () => {
    if (!totalPrice) {
      return null
    }

    return (
      <span className={`${totalDiscountedPrice !== '' ? 'line-through' : ''}`}>
        {totalPrice}
      </span>
    )
  }

  const translatedTrialUnit = () => {
    if (!trial) {
      return
    }

    return t(`checkout.trial.${trial.unit}`, { count: trial.amount })
  }

  const priceWithDiscount = () => {
    if (totalDiscountedPrice === '') {
      return null
    }

    return (
      <span
        className={`${
          totalDiscountedPrice !== '' ? 'inline-block' : 'hidden'
        }`}>
        {totalDiscountedPrice}
      </span>
    )
  }

  const thisInterval = () => {
    const interval = plan.intervalLength === 1 ? 'month' : 'year'

    return t(`checkout.this_${interval}`, {
      price: rampPrice,
    })
  }

  const specialOffer = () => {
    const interval = plan.intervalLength === 1 ? 'month' : 'year'
    return t(`plans.first_${interval}_offer`, {
      price: totalPrice,
    })
  }

  return (
    <div className="text-md font-title font-bold text-ar-green">
      {!trial && (
        <div className="flex flex-col">
          <p className="line-through">{thisInterval()}</p>
          <p className="text-lg">{specialOffer()}</p>
        </div>
      )}
      {trial && (
        <>
          <span>
            {translatedTrialUnit()} {t('checkout.trial_summary')}
          </span>
          {fullPrice()} {priceWithDiscount()}
        </>
      )}
    </div>
  )
}
