interface Props {
  src?: string
  background?: string
}

export const PreLoadImage: React.FC<Props> = ({ src, background }) => {
  if (!!src) {
    return (
      <img
        src={src}
        className="hidden"
        alt="This is hidden asset, is here just to preload"
      />
    )
  }

  if (!!background) {
    return <div className={`${background} invisible`}></div>
  }

  return null
}
