import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Question } from './Question'
import { Answer } from './Answer'

interface Props {}

export const FAQ: React.FC<Props> = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const [showAnswerOne, setShowAnswerOne] = useState(false)
  const [showAnswerTwo, setShowAnswerTwo] = useState(false)
  const [showAnswerThree, setShowAnswerThree] = useState(false)

  const answerOne = (
    <>
      <Answer text={t('plans.faq_subscription_devices_description_1')} />
      <Answer text={t('plans.faq_subscription_devices_description_2')} />
      <Answer text={t('plans.faq_subscription_devices_description_3')} />
    </>
  )

  const answerTwo = (
    <>
      <Answer text={t('plans.faq_lang_supported_description_1')} />
      <Answer text={t('plans.faq_lang_supported_description_2')} />
    </>
  )

  const answerThree = (
    <>
      <Answer text={t('plans.faq_subscription_laptop_description_1')} />
      <Answer text={t('plans.faq_subscription_laptop_description_2')} />
    </>
  )

  return (
    <>
      <h1 className="mb-6 mt-14 text-center font-body text-xl text-ar-dark-gray">
        <b>{t('plans.faq_title')}</b>
      </h1>
      <div className="px-3 pt-3">
        <div onClick={() => setShowAnswerOne(!showAnswerOne)}>
          <Question text={t('plans.faq_subscription_devices_title')} />
        </div>
        <div
          className={`overflow-hidden ${
            showAnswerOne ? 'max-h-96' : 'max-h-0'
          } transition-all duration-500 ease-in`}>
          {answerOne}
        </div>
        <hr className="mt-3 opacity-70" />
      </div>

      <div className="px-3 pt-3">
        <div onClick={() => setShowAnswerTwo(!showAnswerTwo)}>
          <Question text={t('plans.faq_lang_supported_title')} />
        </div>
        <div
          className={`overflow-hidden ${
            showAnswerTwo ? 'max-h-96' : 'max-h-0'
          } transition-all duration-500 ease-in`}>
          {answerTwo}
        </div>
        <hr className="mt-3 opacity-70" />
      </div>

      <div className="px-3 pt-3">
        <div onClick={() => setShowAnswerThree(!showAnswerThree)}>
          <Question text={t('plans.faq_subscription_laptop_title')} />
        </div>
        <div
          className={`overflow-hidden ${
            showAnswerThree ? 'max-h-96' : 'max-h-0'
          } transition-all duration-500 ease-in`}>
          {answerThree}
        </div>
        <hr className="mt-3 opacity-70" />
      </div>
    </>
  )
}
