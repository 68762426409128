import { useState } from 'react'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import useApi from 'hooks/useApi'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Header } from 'components/Header'
import { Heading } from 'components/Heading'
import Input from 'components/Input'
import { useNavigate } from 'react-router-dom'

interface Props {
  locale: string
  nextRoute: string
}

export const ForgotPassword: React.FC<Props> = ({ locale, nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const { passwordResetEmail } = useApi(locale)

  const onContinue = async () => {
    setLoading(true)

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'forgot_password',
    })

    try {
      const { errors } = await passwordResetEmail({ email })

      if (errors) {
        setLoading(false)
        toast.error(t('forgot_password.email_already_sent'))
      } else {
        setLoading(false)
        navigate(nextRoute, { state: { email } })
      }
    } catch (error: any) {
      Sentry.captureException(error)
      setLoading(false)
      toast.error(error.message)
    }
  }

  return (
    <div className="pt-8">
      <Header color="black" />
      <div className="mx-auto flex flex-col justify-start px-8 pt-9 lg:w-5/6 xl:w-5/12">
        <Heading
          text={t('forgot_password.headline')}
          textColor="ar-dark-gray"
        />

        <div className="mt-16 mb-32">
          <Input
            id="email_input"
            placeholder="email@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            inputMode="email"
            darkMode={false}
          />
        </div>
      </div>
      <ToastContainer position="bottom-center" transition={Slide} />
      <ContinueButton
        onClick={onContinue}
        buttonText={t('forgot_password.submit').toUpperCase()}
        disabledText={t('forgot_password.submit').toUpperCase()}
        disabled={loading}
      />
    </div>
  )
}
