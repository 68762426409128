import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useSnowplow from 'hooks/useSnowplow'
import { CouponResponse } from 'hooks/useApi'

interface Props {
  discountCoupon?: CouponResponse | null
  couponCode: string | null
  removeCouponCode: () => void
  setShowCouponInput: (showCouponInput: boolean) => void
  showCouponInput: boolean
  couponCodeInputText: string
  setCouponCodeInputText: (couponCodeInputText: string) => void
  applyCoupon: () => void
  fetchingCoupon: boolean
}

export const DiscountCoupon: React.FC<Props> = ({
  discountCoupon,
  couponCode,
  removeCouponCode,
  setShowCouponInput,
  showCouponInput,
  couponCodeInputText,
  setCouponCodeInputText,
  applyCoupon,
  fetchingCoupon,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const couponInputRef = useRef<HTMLInputElement>(null)

  const showInput = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'coupon_input_opened',
    })

    setShowCouponInput(true)
    setTimeout(
      () => couponInputRef.current?.scrollIntoView({ behavior: 'smooth' }),
      100,
    )
    setTimeout(() => couponInputRef.current?.focus(), 300)
  }

  return (
    <div className="mt-5">
      {discountCoupon ? (
        <div>
          <span className="text-md pt-10 font-body text-ar-green">
            {t('checkout.coupon_code')}:&nbsp;{couponCode}&nbsp;
          </span>
          <span
            className="text-md cursor-pointer pt-10 font-title text-lg text-ar-green"
            onClick={() => {
              snowplowTrack({
                eventName: 'navigation_click',
                property: 'coupon_code_removed',
              })

              removeCouponCode()
            }}>
            {t('checkout.remove_coupon_code')}
          </span>
        </div>
      ) : (
        <span
          className="text-md cursor-pointer pt-10 font-body text-ar-green"
          onClick={showInput}>
          {t('checkout.add_coupon_code')}
        </span>
      )}
      {showCouponInput && (
        <div className="mt-4 flex w-full justify-between space-x-5">
          <input
            type="text"
            value={couponCodeInputText}
            ref={couponInputRef}
            onChange={(e) => setCouponCodeInputText(e.target.value)}
            placeholder={t('checkout.coupon_code')}
            className="flex-grow rounded-lg border border-gray-300 p-3 font-body text-sm focus:outline-none"
          />
          <button
            type="button"
            onClick={() => {
              snowplowTrack({
                eventName: 'navigation_click',
                property: 'coupon_code_redeemed',
              })

              applyCoupon()
            }}
            disabled={!couponCodeInputText || fetchingCoupon}
            className={`text-md m-auto block rounded-lg bg-ar-green px-10 py-4 text-center font-link text-white focus:outline-none
                    ${
                      couponCodeInputText && !fetchingCoupon
                        ? ''
                        : 'cursor-default bg-opacity-70'
                    }`}>
            {t('checkout.apply_coupon_code')}
          </button>
        </div>
      )}
    </div>
  )
}
