import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { useEffect, useState } from 'react'
import useApi, { PlanResponse } from 'hooks/useApi'
import usePlanData from 'hooks/usePlanData'
import useFacebook from 'hooks/useFacebook'
import useSnowplow from 'hooks/useSnowplow'
import { Slide, toast, ToastContainer } from 'react-toastify'
import useFacebookCookies from 'hooks/useFacebookCookies'

interface Props {
  nextRoute: string
}

export const MeditationPlan: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const [planCurrencyBased, setPlanCurrencyBased] =
    useState<PlanResponse['currencies'][0]>()
  const [planPrice, setPlanPrice] = useState<number>()
  const [originalPlanPrice, setOriginalPlanPrice] = useState<string>()
  const [showBuyNowModal, setShowBuyNowModal] = useState<boolean>(false)
  const [submittingPayment, setSubmittingPayment] = useState<boolean>(false)

  const { user } = useUser()
  const { facebookPixelTrackCustom } = useFacebook()
  const { snowplowTrack } = useSnowplow()
  const { fbp, fbc } = useFacebookCookies()

  const { currency } = usePlanData(user?.language || 'en')
  const { getPlan, createMeditationSubscription } = useApi(
    user?.language || 'en',
  )
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPlan = async () => {
      const response = await getPlan('12m_meditation')
      if (!response.error) {
        setPlanCurrencyBased(
          response.plan.currencies.find((c) => c.currency === currency),
        )
      } else {
        toast.error(t('errors.unexpected_error'))
      }
    }

    fetchPlan()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (planCurrencyBased) {
      setPlanPrice(planCurrencyBased.unit_amount)
      setOriginalPlanPrice(
        Math.ceil(planCurrencyBased.unit_amount * 2).toFixed(2),
      )
    }
  }, [planCurrencyBased])

  const onBuyNowModalClick = async () => {
    setSubmittingPayment(true)
    const response = await createMeditationSubscription({
      authToken: user?.authToken || '',
      currency,
      fbp,
      fbc,
    })
    if (!response.error && response.tracking) {
      facebookPixelTrackCustom({
        eventName: 'purchase-meditation',
        data: {
          value: response.tracking.value,
          currency: response.tracking.currency,
        },
        eventID: response.tracking.event_id,
      })

      const stringifiedValue = response.tracking.value.toString()

      snowplowTrack({
        eventName: 'meditation-purchase',
        property: response.tracking.currency,
        value: stringifiedValue,
      })

      setTimeout(() => {
        navigate(nextRoute)
      }, 2000)
    } else if (!response.error) {
      navigate(nextRoute)
    } else if (response.error) {
      setSubmittingPayment(false)
      toast.error(response.error)
    } else {
      toast.error(t('errors.unexpected_error'))
    }
  }

  const onBuyNowClick = () => {
    setShowBuyNowModal(true)
  }

  const onMaybeLaterClick = () => {
    navigate(nextRoute)
  }

  return (
    <div className="default-container relative flex flex-col">
      {showBuyNowModal && (
        <div className="fixed inset-0 top-0 left-0 z-10 flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-70">
          <div className="mx-auto w-4/5 rounded-lg bg-white px-8 py-6 lg:w-1/2 xl:w-1/3">
            <p className="text-center font-body text-xl text-ar-dark-gray">
              {t('meditation_pages.plan.confirm_payment_title')}
            </p>

            <div className="mt-4 flex w-full flex-col space-y-4">
              <button
                type="button"
                onClick={onBuyNowModalClick}
                className={`text-md m-auto w-full cursor-pointer rounded-lg bg-ar-green p-5 text-center font-body text-sm text-white focus:outline-none ${
                  submittingPayment
                    ? 'cursor-not-allowed opacity-70'
                    : 'hover:bg-opacity-90'
                }`}>
                {submittingPayment
                  ? t('meditation_pages.plan.processing_payment')
                  : t('meditation_pages.plan.confirm_payment')}
              </button>

              <button
                type="button"
                onClick={onMaybeLaterClick}
                className="text-md m-auto w-full cursor-pointer rounded-lg border border-ar-light-gray p-5 text-center font-body text-sm text-ar-dark-gray hover:opacity-70 focus:outline-none">
                {t('meditation_pages.plan.maybe_later')}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex w-full flex-col bg-gray-100 p-6">
        <div className="flex w-5/6 flex-col items-center self-center xl:w-3/4">
          <p className="font-body text-xl text-ar-dark-gray">
            {t('meditation_pages.plan.introducing')}
          </p>
          <p className="mt-3 font-title text-3xl text-ar-dark-gray">
            {t('meditation_pages.plan.title')}
          </p>
          <p
            className="mt-2 font-body text-sm text-ar-dark-gray"
            dangerouslySetInnerHTML={{
              __html: t('meditation_pages.plan.holistic_health', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
          <img
            src="/images/plan_1.png"
            alt="Meditation Plan"
            className="mt-12 h-auto max-h-[235px] max-w-full"
          />
          <p
            className="mt-12 text-center font-body text-sm text-ar-dark-gray"
            dangerouslySetInnerHTML={{
              __html: t('meditation_pages.plan.holistic_journey', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </div>
      </div>

      <div className="mt-8 flex w-full flex-col items-center p-6">
        <h2 className="text-center font-body">
          {t('meditation_pages.plan.only_for')}
        </h2>
        <p className="mt-2 text-center font-body text-xl text-ar-green">
          {t('meditation_pages.plan.twelve_months')}{' '}
          <span className="line-through">
            {originalPlanPrice} {currency}
          </span>{' '}
          {planPrice} {currency}
        </p>
        <h3 className="mt-5 font-body text-sm">
          {t('meditation_pages.plan.expand_habits')}
        </h3>
        <div className="mt-6 space-y-4">
          <div className="flex flex-row justify-center space-x-2">
            <img
              src="/images/checkmark.png"
              alt="Checkmark"
              className="mb-2 h-4"
            />
            <p className="text-center text-sm">
              {t('meditation_pages.plan.checkmark_1')}
            </p>
          </div>
          <div className="flex flex-row justify-center space-x-2">
            <img
              src="/images/checkmark.png"
              alt="Checkmark"
              className="mb-2 h-4"
            />
            <span className="text-center text-sm">
              {t('meditation_pages.plan.checkmark_2')}
            </span>
          </div>
          <div className="flex flex-row justify-center space-x-2">
            <img src="/images/checkmark.png" alt="Checkmark" className="h-4" />
            <span className="text-center text-sm">
              {t('meditation_pages.plan.checkmark_3')}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-8 max-h-[350px] w-full">
        <img
          src="/images/plan_2.jpg"
          alt="Plan"
          className="h-full w-full object-cover"
        />
      </div>
      <div className="flex flex-col px-10 pt-10 pb-60">
        <div className="flex w-full flex-col items-center">
          <h3 className="text-center font-body text-sm text-ar-dark-gray">
            {t('meditation_pages.plan.tranquility_title')}
          </h3>
          <p className="mt-4 text-center font-body text-sm text-ar-dark-gray">
            {t('meditation_pages.plan.tranquility_copy')}
          </p>
        </div>

        <div className="mt-10 flex w-full flex-col items-center">
          <h3 className="text-center font-body text-sm text-ar-dark-gray">
            {t('meditation_pages.plan.sleep_experience_title')}
          </h3>
          <p className="mt-4 text-center font-body text-sm text-ar-dark-gray">
            {t('meditation_pages.plan.sleep_experience_copy')}
          </p>
        </div>

        <div className="mt-10 flex w-full flex-col items-center">
          <h3 className="text-center font-body text-sm text-ar-dark-gray">
            {t('meditation_pages.plan.sleep_peacefully_title')}
          </h3>
          <p className="mt-4 text-center font-body text-sm text-ar-dark-gray">
            {t('meditation_pages.plan.sleep_peacefully_copy')}
          </p>
        </div>

        <div className="fixed right-0 bottom-0 left-0 border-t border-ar-light-gray border-opacity-10 bg-white py-2">
          <div className="mx-8 my-2 space-y-4 xl:mx-36">
            <button
              type="button"
              onClick={onBuyNowClick}
              className="text-md m-auto block w-full cursor-pointer rounded-lg bg-ar-green p-5 text-center font-body text-white hover:bg-opacity-90 focus:outline-none lg:w-5/6 lg:p-4 xl:w-5/12 xxsm:p-3">
              {t('meditation_pages.plan.buy_now')}
            </button>

            <button
              type="button"
              onClick={onMaybeLaterClick}
              className="text-md m-auto block w-full cursor-pointer rounded-lg border border-ar-light-gray p-5 text-center font-body text-ar-dark-gray hover:opacity-70 lg:w-5/6 lg:p-4 xl:w-5/12 xxsm:p-3">
              {t('meditation_pages.plan.maybe_later')}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-center" transition={Slide} />
    </div>
  )
}
