import React, { useState, useRef, useEffect } from 'react'
import { addMonths } from 'date-fns'
import * as Sentry from '@sentry/react'
import { ToastContainer, toast, Slide } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useUser } from 'context/UserContext'
import usePricing from 'hooks/usePricing'
import useFacebook from 'hooks/useFacebook'
import useSnowplow from 'hooks/useSnowplow'
import useApi, {
  SubscriptionError,
  SubscriptionObject,
  CouponResponse,
} from 'hooks/useApi'
import { useRecurly } from '@recurly/react-recurly'
import { LOCAL_STORAGE_DISCOUNT_COUPON } from 'components/App'
import { ContinueButton } from 'components/ContinueButton'
import { localeFormat } from 'i18n/localeFormat'
import { Plan } from 'pages/Plans'
import { CreditCard } from './CreditCard'
import { PayPal } from './PayPal'
import { DiscountCoupon } from './DiscountCoupon'
import { OrderSummary } from './OrderSummary'

interface Props {
  locale: string
  authToken: string
  braintreeClientToken: string
  plan: Plan
  currency: string
  country?: string
  nextRoute: string
}

interface PayPalComponent {
  submitPayment(): void
}

interface CreditCardComponent {
  submitPayment(arg0: object): void
}

export interface Trial {
  amount: number
  unit: string
}

export const Form: React.FC<Props> = ({
  locale,
  authToken,
  braintreeClientToken,
  plan,
  currency,
  country,
  nextRoute,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { getCoupon } = useApi(locale)

  const { facebookPixelTrack } = useFacebook()
  const { snowplowTrack } = useSnowplow()

  const discountCode = localStorage.getItem(LOCAL_STORAGE_DISCOUNT_COUPON)
  const recurly = useRecurly()
  const paypalRef = useRef<PayPalComponent | null>(null)
  const creditCardRef = useRef<CreditCardComponent | null>(null)
  const navigate = useNavigate()
  const { user, setUser } = useUser()
  const { country: _userCountry } = user
  const userCountry = country || _userCountry
  const [showCouponInput, setShowCouponInput] = useState(false)
  const [couponCode, setCouponCode] = useState<string | null>(
    discountCode || null,
  )
  const [couponCodeInputText, setCouponCodeInputText] = useState('')
  const [discountCoupon, setDiscountCoupon] = useState<
    CouponResponse | undefined | null
  >()
  const [fetchingCoupon, setFetchingCoupon] = useState(false)

  const { discountedPrice, fullyDiscounted, rampPrice } = usePricing(
    plan,
    currency,
    discountCoupon,
  )

  const removeCouponCode = () => {
    localStorage.setItem(LOCAL_STORAGE_DISCOUNT_COUPON, '')
    setDiscountCoupon(null)
    setCouponCode('')
    setCouponCodeInputText('')
  }

  const applyCoupon = () => {
    localStorage.setItem(LOCAL_STORAGE_DISCOUNT_COUPON, couponCodeInputText)
    setCouponCode(couponCodeInputText)
  }

  const [paymentOption, setPaymentOption] = useState('')
  const [loading, setLoading] = useState(false)

  const parseProceeds = (proceeds: number | string | null) => {
    switch (typeof proceeds) {
      case 'string':
        return parseFloat(proceeds)
      case 'number':
        return proceeds
      default:
        Sentry.captureException(
          'parseProceeds: Proceeds is not a number or string',
        )
        return null
    }
  }

  const trackSuccessfulSubscription = (subscription: SubscriptionObject) => {
    setUser({
      ...user,
      subscription,
    })

    let { event_data: eventData } = subscription

    const proceeds = eventData?.proceeds
    const eventId = eventData?.id
    const parsedProceeds = proceeds ? parseProceeds(proceeds) : null

    if (!!parsedProceeds && !!eventId) {
      facebookPixelTrack({
        eventName: 'Purchase',
        data: {
          value: parsedProceeds,
          currency: currency,
        },
        eventID: eventId,
      })

      const stringifiedProceeds = parsedProceeds
        ? parsedProceeds.toString()
        : ''

      snowplowTrack({
        eventName: 'conversion',
        property: currency,
        value: stringifiedProceeds,
      })
    }

    navigate(nextRoute)
  }

  const handleSubmit = () => {
    setLoading(true)

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'submit_payment',
      label: paymentOption,
    })

    switch (paymentOption) {
      case 'paypal':
        paypalRef?.current?.submitPayment()
        break
      case 'cc':
        creditCardRef?.current?.submitPayment({})
        break
    }
  }

  const handleError = (
    error: SubscriptionError | Error | { message: string } | string,
  ) => {
    setLoading(false)

    const errorMessage = typeof error === 'string' ? error : error.message

    toast.error(errorMessage)
  }

  const duration = () =>
    t('plans.months', {
      count: plan.intervalLength,
    })

  const formattedRenewalDate = () => {
    const now = new Date()
    const nextRenewalDate = addMonths(now, plan.intervalLength)

    return localeFormat(nextRenewalDate, 'PPP')
  }

  const handleCouponResponse = (
    error?: { message: string },
    coupon?: CouponResponse,
  ) => {
    if (error) {
      removeCouponCode()
      handleError(error)
      return
    }

    if (!coupon) {
      removeCouponCode()
      return
    }

    const isPlanSpecificAndIncludesPlan =
      coupon.plan_codes.length > 0 && !coupon.plan_codes.includes(plan.code)
    const isNotRedeemable = coupon.state !== 'redeemable'

    if (isPlanSpecificAndIncludesPlan || isNotRedeemable) {
      removeCouponCode()
      handleError(t('checkout.coupon.unavailable_for_plan'))
      return
    }

    toast.success(t('checkout.coupon.success'))

    setShowCouponInput(false)
    setDiscountCoupon(coupon)
  }

  const redeemCoupon = async () => {
    if (!couponCode) {
      return
    }

    try {
      setFetchingCoupon(true)
      const response = await getCoupon(couponCode, plan.code, currency)

      if (response.coupon_code === couponCode) {
        handleCouponResponse(undefined, response)
      } else {
        handleCouponResponse(
          { message: t('checkout.coupon_invalid') },
          undefined,
        )
      }
    } catch (e) {
      handleCouponResponse({ message: t('errors.unexpected_error') }, undefined)
    } finally {
      setFetchingCoupon(false)
    }
  }

  useEffect(() => {
    redeemCoupon()
  }, [couponCode, plan]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!discountedPrice && !fullyDiscounted) {
      return
    }

    setPaymentOption('cc')
  }, [discountedPrice, fullyDiscounted])

  return (
    <div className="default-container flex flex-col">
      <div className="no-scrollbar flex h-full flex-col self-center overflow-auto px-7 pt-7 lg:w-5/6 xl:w-7/12">
        <h1 className="text-left font-title text-2xl font-bold text-ar-dark-gray">
          {t('checkout.title')}
        </h1>
        <div className="mt-6 mb-28 grid grid-cols-1 sm:grid-flow-row sm:grid-cols-2 sm:gap-6">
          <div className="my-5 flex w-full flex-col space-y-4">
            <strong className="text-md font-title text-ar-green">
              {t('checkout.secure_title')}
            </strong>
            <p className="font-body text-sm">
              {t('checkout.secure_description')}
            </p>
          </div>
          <div className="mb-5 flex w-full flex-col space-y-4 md:my-5 md:mb-0">
            <strong className="text-md font-title text-ar-green">
              {t('checkout.money_back_title')}
            </strong>
            <p className="font-body text-sm text-ar-dark-gray">
              {t('checkout.money_back_description')}
            </p>
          </div>

          <div className="sm:row-span-2">
            <div className="flex flex-col space-y-4">
              <CreditCard
                fullyDiscounted={fullyDiscounted}
                recurly={recurly}
                setPaymentOption={setPaymentOption}
                couponCode={couponCode}
                paymentOption={paymentOption}
                handleError={handleError}
                setLoading={setLoading}
                authToken={authToken}
                currency={currency}
                planCode={plan.code}
                locale={locale}
                userCountry={userCountry}
                trackSuccessfulSubscription={trackSuccessfulSubscription}
                ref={creditCardRef}
              />
              <PayPal
                recurly={recurly}
                fullyDiscounted={fullyDiscounted}
                setPaymentOption={setPaymentOption}
                paymentOption={paymentOption}
                braintreeClientToken={braintreeClientToken}
                couponCode={couponCode}
                handleError={handleError}
                setLoading={setLoading}
                authToken={authToken}
                currency={currency}
                planCode={plan.code}
                locale={locale}
                userCountry={userCountry}
                trackSuccessfulSubscription={trackSuccessfulSubscription}
                ref={paypalRef}
              />
              <DiscountCoupon
                discountCoupon={discountCoupon}
                couponCode={couponCode}
                removeCouponCode={removeCouponCode}
                setShowCouponInput={setShowCouponInput}
                showCouponInput={showCouponInput}
                couponCodeInputText={couponCodeInputText}
                setCouponCodeInputText={setCouponCodeInputText}
                applyCoupon={applyCoupon}
                fetchingCoupon={fetchingCoupon}
              />
            </div>
          </div>

          <div className="pb-10">
            <div className="mt-5 h-full rounded-lg p-6 pt-1 shadow-checkout-button ring-1 ring-ar-white sm:mt-0">
              <div className="flex flex-col justify-between space-y-5">
                <h2 className="mt-5 text-left font-title text-2xl text-ar-dark-gray">
                  {t('checkout.order_summary')}
                </h2>

                <div className="font-body text-sm text-black">
                  <p className="font-title">{t('checkout.full_access')}</p>
                  <p>{t('checkout.full_access_description')}</p>
                </div>

                <div className="font-body text-sm text-black">
                  <p className="font-title">{t('checkout.duration')}</p>
                  <p>{duration()}</p>
                </div>

                <div className="font-body text-sm text-black">
                  <p className="font-title">{t('checkout.renew')}</p>
                  {formattedRenewalDate()}
                  <p className="text-ar-light-gray">
                    {t('checkout.renew_description')}
                  </p>
                </div>
                <OrderSummary
                  rampPrice={rampPrice}
                  currency={currency}
                  plan={plan}
                  coupon={discountCoupon}
                />
              </div>
            </div>
          </div>
        </div>
        <ContinueButton
          id={
            paymentOption === 'cc'
              ? 'submit_payment_credit_card'
              : 'submit_payment_paypal'
          }
          onClick={handleSubmit}
          buttonText={t('checkout.submit').toUpperCase()}
          disabled={(!fullyDiscounted && paymentOption === '') || loading}
          disabledText={
            loading ? t('checkout.submitting') : t('checkout.title')
          }
        />
        <ToastContainer
          position="bottom-center"
          hideProgressBar
          transition={Slide}
        />
      </div>
    </div>
  )
}
