import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
  small?: boolean | undefined
}

export const ButtonWrapper: React.FC<Props> = ({ children, small }) => {
  return (
    <div className="mt-10 flex w-full flex-col justify-end self-center lg:w-5/6">
      <div className="grid grid-cols-1 justify-center gap-4">{children}</div>
    </div>
  )
}
