import { createContext, useContext, Dispatch, SetStateAction } from 'react'

import { SubscriptionObject, Experiment } from 'hooks/useApi'

export interface GoalType {
  key: number
  display: string
  value: string
  position: string
  selected: boolean
  id: string
}

export interface EmotionType {
  key: number
  display: string
  value: string
  selected: boolean
  id: string
  positive_emotion: number
}

export interface User {
  id?: number
  gender?: string
  goals?: string[]
  symptoms?: string[]
  emotions?: string[]
  agingDiet?: string
  agingActivity?: string
  goalTypes?: GoalType[]
  emotionTypes?: EmotionType[]
  emotionColor?: number[]
  firstGoal?: GoalType
  height?: string
  heightType?: string
  weight?: string
  weightType?: string
  goalWeight?: string
  fitness?: string
  energy?: string
  activity?: string
  feelings?: string
  GoalWeightQuestion?: string
  dateOfBirth?: string
  goalAge?: string
  sleepTime?: string
  sittingTime?: string
  diet?: string
  password?: string
  email?: string
  language?: string
  country?: string
  zip?: string
  state?: string
  city?: string
  currency?: string
  ip?: string
  authToken?: string
  subscription?: SubscriptionObject
  recipientEmailAddress?: string
  hasPassword?: boolean
  assignments?: Record<string, string>
  experiments?: Experiment[]
  agingQuestionChoiceId?: number
  experienceYogaChoiceId?: number
}

interface UserState {
  user: User
  setUser: Dispatch<SetStateAction<User | undefined>>
}

const UserContext = createContext({} as UserState)

export const useUser = () => useContext(UserContext)

export default UserContext
