interface Props {}

// this whole component needs splitting into functions and smaller components
export const PlanBoxLoading: React.FC<Props> = () => {
  return (
    <div className="mt-5 justify-center -tracking-[.05em] sm:flex">
      <div className="box-content w-full border-2 border-ar-green bg-ar-white text-center md:flex md:text-left">
        <div className="animate-pulse md:mb-4 md:w-1/2 md:pl-8">
          <p className="mt-5 font-title text-sm uppercase text-ar-light-gray ">
            ◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼
          </p>

          <p className="mt-4 font-link text-2xl text-ar-green">
            ◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼
          </p>

          <p className="mt-4 font-link text-2xl text-ar-light-gray">
            ◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼◼
          </p>

          <p className="mt-4 font-body text-sm text-ar-green line-through">
            ◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼
          </p>
          <span className="font-body text-sm text-ar-light-gray ">
            ◼◼◼◼◼ ◼◼◼◼◼◼ ◼◼◼◼◼ ◼◼◼
          </span>

          <div className="md:hidden">
            <button
              type="button"
              className="text-md m-auto mt-6 block w-4/5 rounded-lg bg-ar-green p-5 text-center font-link text-white focus:outline-none">
              ◼◼◼◼ ◼◼◼◼◼◼◼
            </button>

            <div className="flex flex-col gap-4 p-8">
              <p className="font-body text-sm text-ar-light-gray ">
                ◼◼◼◼◼ ◼◼◼◼◼◼ ◼◼◼◼◼ ◼◼◼
              </p>
              <p className="font-title text-sm text-ar-light-gray ">
                ◼◼◼◼ ◼◼◼◼◼◼◼
              </p>
            </div>
          </div>
        </div>
        <div className="hidden md:ml-32 md:block md:w-1/2">
          <button
            type="button"
            className="text-md mt-12 block w-3/4 rounded-lg bg-ar-green p-4 text-center font-link text-white focus:outline-none">
            ◼◼ ◼◼◼◼◼◼◼◼◼◼◼◼
          </button>

          <div className="mt-4 flex flex-col gap-4">
            <p className="font-body text-sm text-ar-light-gray ">
              ◼◼◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼◼ ◼◼◼◼
            </p>
            <p className="rounded-3 font-title text-sm text-ar-light-gray">
              ◼◼◼◼◼◼◼◼◼ ◼◼◼◼◼◼◼◼ ◼◼◼◼
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
