import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { ChoiceButton } from 'components/ChoiceButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const HowOftenExercise: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()
  const { user } = useUser()

  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-workouttime-male bg-center'
      : 'bg-workouttime-female bg-center'

  const onButtonClick = () => {
    navigate(nextRoute)
  }

  return (
    <div className={`default-container ${genderBasedBackground} flex flex-col`}>
      <div className="no-scrollbar flex h-full flex-col justify-between self-center overflow-auto px-7 pb-10 pt-7 lg:w-5/6 xl:w-5/12">
        <Heading text={t('how_often_exercise.headline')} />

        <ButtonWrapper>
          <ChoiceButton
            onClick={onButtonClick}
            value={t('how_often_exercise.option_1')}
            id="option_1"
          />
          <ChoiceButton
            onClick={onButtonClick}
            value={t('how_often_exercise.option_2')}
            id="option_2"
          />
          <ChoiceButton
            onClick={onButtonClick}
            value={t('how_often_exercise.option_3')}
            id="option_3"
          />
          <ChoiceButton
            onClick={onButtonClick}
            value={t('how_often_exercise.option_4')}
            id="option_4"
          />
          <ChoiceButton
            onClick={onButtonClick}
            value={t('how_often_exercise.option_5')}
            id="option_1"
          />
          <ChoiceButton
            onClick={onButtonClick}
            value={t('how_often_exercise.option_6')}
            id="option_1"
          />
        </ButtonWrapper>
      </div>
    </div>
  )
}
