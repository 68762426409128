import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const FeedbackExerciseRoutine: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user } = useUser()
  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-feedback-exercise-routine-male bg-center'
      : 'bg-feedback-exercise-routine-female bg-center'
  const navigate = useNavigate()

  const onContinue = () => {
    navigate(nextRoute)
  }

  return (
    <>
      <div
        className={`default-container ${genderBasedBackground} flex flex-col`}>
        <div className="flex flex-col justify-between self-center px-8 pt-7 lg:w-5/6 xl:w-5/12">
          <Heading text={t('feedback_exercise_routine.headline')} centered />
        </div>
        <div className="mt-48 flex w-5/6 flex-col self-center rounded-xl bg-white p-6 text-center lg:w-5/6 xl:w-5/12">
          <p className="font-body text-sm text-ar-dark-gray">
            {t('feedback_exercise_routine.did_you_know.title')}
          </p>
          <p
            className="my-4 font-body text-sm text-ar-dark-gray xl:text-center"
            dangerouslySetInnerHTML={{
              __html: t('feedback_exercise_routine.did_you_know.body'),
            }}
          />
        </div>
      </div>
      <ContinueButton onClick={onContinue} />
    </>
  )
}
