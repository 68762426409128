import {
  enUS,
  de,
  fr,
  ptBR,
  it,
  es,
  ja,
  ko,
  zhCN,
  ru,
  hi,
  pl,
  cs,
  sv,
  nb,
  da,
  fi,
  nl,
  hr,
  ro,
  el,
  hu,
} from 'date-fns/locale'

import { format, parseISO } from 'date-fns'
import i18n from 'i18next'

const formatLocale: { [key: string]: Locale } = {
  en: enUS,
  de,
  fr,
  pt: ptBR,
  it,
  es,
  ja,
  ko,
  zh: zhCN,
  ru,
  hi,
  pl,
  cs,
  sv,
  no: nb,
  da,
  fi,
  nl,
  hr,
  ro,
  el,
  hu,
}

export const localeFormat = (
  date: Date | string,
  formatString: string,
): string => {
  if (typeof date === 'string') {
    date = parseISO(date)
  }

  return format(date, formatString, {
    locale: formatLocale[i18n.language] || enUS,
  })
}
