import { useState, useEffect } from 'react'
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { useUser } from 'context/UserContext'
import { useKeyPress } from 'hooks/useKeyPress'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Header } from 'components/Header'
import { Heading } from 'components/Heading'
import Input from 'components/Input'
import useApi, { Experiment } from 'hooks/useApi'
import { RoutePaths } from 'route-paths'
import { Modal } from 'components/Modal'

interface Props {
  locale: string
}

interface LocationState {
  message: string
}

export const Login: React.FC<Props> = ({ locale }) => {
  const [redirectPath, setRedirectPath] = useState('')

  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const { user, setUser } = useUser()
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false)
  const { signIn, getExperiments } = useApi(locale)
  const [search] = useSearchParams()

  useEffect(() => {
    const redirectPathFromParams = search.get('redirect_path')
    if (redirectPathFromParams) {
      setRedirectPath(redirectPathFromParams)
    }
  }, [search])

  useEffect(() => {
    if (
      (location.state as LocationState) &&
      (location.state as LocationState).message
    ) {
      setMessage((location.state as LocationState).message)
    }
  }, [location])

  useEffect(() => {
    if (message) {
      toast(message, {
        type: 'error',
        position: 'bottom-center',
      })
    }
  }, [message])

  const onContinue = async () => {
    setLoading(true)

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'login_button',
    })

    try {
      const response = await signIn({
        email: email,
        password: password,
      })

      if (!!response.token) {
        const experiments = await fetchUserAssignments(response.token)
        setUserAndRedirect(response, experiments)
      } else {
        setLoading(false)
        setShowPasswordResetModal(true)
        console.error(response)
        Sentry.captureException(response)
      }
    } catch (error) {
      setLoading(false)
      toast.error(t('errors.unexpected_error'))
      console.error(error)
      Sentry.captureException(error)
    }
  }

  const fetchUserAssignments = async (token: string): Promise<Experiment[]> => {
    const response = await getExperiments({
      authToken: token,
      experiments: process.env.REACT_APP_EXPERIMENTS || '',
    })

    return response.experiments
  }

  const setUserAndRedirect = (response: any, experiments: Experiment[]) => {
    setLoading(false)

    const latestSubscription = response.user.latest_subscription

    setUser({
      ...user,
      ...response.user,
      authToken: response.token,
      hasPassword: true,
      experiments,
      assignments: Object.fromEntries(
        experiments.map((e: any) => [e.name, e.variant]),
      ),
      ...(latestSubscription && {
        subscription: {
          ...latestSubscription,
          renewal_date: latestSubscription.expires_date,
        },
      }),
    })

    if (redirectPath) {
      navigate(redirectPath, { replace: true })
    } else {
      window.location.reload()
    }
  }

  useKeyPress('ENTER', onContinue)

  return (
    <div className="pt-8">
      <Modal
        onClose={() => setShowPasswordResetModal(false)}
        open={showPasswordResetModal}>
        <div className="flex flex-col gap-3 px-8 py-10 text-center">
          <Heading
            text={t('forgot_password.incorrect_email_or_password')}
            centered
            textColor="ar-dark-gray"
          />
          <button
            id="password_reset_modal"
            type="button"
            onClick={() => {
              snowplowTrack({
                eventName: 'navigation_click',
                property: 'forgot_password',
              })
              navigate(RoutePaths.ForgotPassword)
            }}
            className="text-md mt-6 block w-full rounded-lg bg-ar-green p-4 text-center font-link text-white focus:outline-none">
            {t('forgot_password.submit')}
          </button>
          <p
            className="mt-2 cursor-pointer font-body text-xs"
            onClick={() => {
              snowplowTrack({
                eventName: 'navigation_click',
                property: 'signup',
              })
              navigate('/')
            }}>
            {t('forgot_password.new_to_asana_rebel')}{' '}
            <span className="font-bold text-ar-green">
              {t('login.signup')}.
            </span>
          </p>
        </div>
      </Modal>

      <Header color="black" />
      <div className="mx-auto flex flex-col justify-start px-8 pt-9 lg:w-5/6 xl:w-5/12">
        <Heading text={t('login.title')} textColor="ar-dark-gray" />

        <div className="mt-8 mb-36 flex flex-col gap-3">
          <Input
            id="email_input"
            placeholder={t('login.email_placeholder')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            inputMode="email"
            darkMode={false}
          />

          <Input
            id="password_input"
            placeholder={t('login.password_placeholder')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            inputMode="text"
            darkMode={false}
          />

          <span
            onClick={() => {
              snowplowTrack({
                eventName: 'navigation_click',
                property: 'forgot_password',
              })
              navigate(RoutePaths.ForgotPassword)
            }}
            className="cursor-pointer text-center font-link text-sm text-ar-green">
            {t('login.forgot_password')}
          </span>
        </div>
      </div>
      <ToastContainer />

      <ContinueButton
        onClick={onContinue}
        buttonText={t('login.login').toUpperCase()}
        disabled={loading}
        disabledText={loading ? t('login.logging_in') : t('login.login')}>
        <span className="mt-3 block w-full text-center font-body text-sm text-ar-dark-gray">
          {t('login.no_account')}{' '}
          <span
            onClick={() => {
              snowplowTrack({
                eventName: 'navigation_click',
                property: 'signup',
              })
              navigate('/')
            }}
            className="cursor-pointer font-link text-ar-green">
            {t('login.signup')}
          </span>
        </span>
      </ContinueButton>
    </div>
  )
}
