import { useTranslation } from 'react-i18next'
import { ReactComponent as Arrow } from './icons/arrow.svg'

interface Props {
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  point: number
  percentage: number
  direction?: 'up' | 'down'
}

export const BulletPoint: React.FC<Props> = ({
  icon: Icon,
  point,
  percentage,
  direction = 'up',
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  return (
    <li className="my-2 flex h-[22px] leading-[22px]">
      <span className="inline-block h-[22px] w-1/12 pt-0.5">
        <Icon className="mr-2" />
      </span>
      <span className="w-9/12 font-body text-sm text-ar-dark-gray">
        {t(`health_forecast.bullet_${point}.text`)}
      </span>
      <span className="flex w-2/12 justify-end text-right font-body text-sm text-ar-dark-gray">
        <Arrow
          className={`mt-[4px] w-[12px] justify-center ${
            direction === 'down' ? 'rotate-180' : ''
          }`}
        />
        <span className="inline-block w-[30px] text-center font-bold">
          {percentage}%
        </span>
      </span>
    </li>
  )
}
