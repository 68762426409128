import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ContinueButton } from 'components/ContinueButton'
import { BulletPoint } from './BulletPoint'
import { ReactComponent as Heart } from './icons/heart.svg'
import { ReactComponent as Flexibility } from './icons/flexibility.svg'
import { ReactComponent as Stress } from './icons/stress.svg'
import { ReactComponent as Fat } from './icons/fat.svg'
import { ReactComponent as Core } from './icons/core.svg'
import { ReactComponent as Antioxidants } from './icons/antioxidants.svg'
import { ReactComponent as VitaminC } from './icons/c.svg'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const FeedbackRisks: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()

  const onButtonClick = () => {
    navigate(nextRoute)
  }

  return (
    <>
      <div className="container mx-auto px-9 pt-7 md:text-center">
        <Heading text={t('feedback_risks.headline')} textColor="black" />
        <div
          className="radius-4 container mx-auto my-12
        max-w-[500px] rounded-[10px] px-4 text-center
        shadow-feedback-risks">
          <h3
            className="m-h-[40px] font-link text-xs leading-[40px]
          text-ar-dark-gray sm:text-base sm:leading-[40px]">
            {t('feedback_risks.box_headline')}
          </h3>

          <ul className="flex flex-col border-y-[1px] border-solid border-[#eeeff1] py-4 text-left">
            <BulletPoint
              icon={Heart}
              point={1}
              direction="down"
              percentage={21}
            />
            <BulletPoint icon={Flexibility} point={2} percentage={35} />
            <BulletPoint
              icon={Stress}
              point={3}
              direction="down"
              percentage={50}
            />
            <BulletPoint
              icon={Fat}
              point={4}
              direction="down"
              percentage={26}
            />
            <BulletPoint icon={Core} point={5} percentage={24} />
            <BulletPoint icon={Antioxidants} point={6} percentage={29} />
            <BulletPoint icon={VitaminC} point={7} percentage={10} />
          </ul>

          <small className="mb-1 inline-block h-[24px] font-body text-xs text-[#999c9f]">
            {t('feedback_risks.box_footer')}
          </small>
        </div>
        <ContinueButton onClick={onButtonClick} />
      </div>
    </>
  )
}
