import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { Heading } from 'components/Heading'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { ChoiceButton } from 'components/ChoiceButton'

interface Props {
  nextRoute: string
}

export const ExerciseRoutine: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user } = useUser()
  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-exercise-routine-male bg-center'
      : 'bg-exercise-routine-female bg-center'
  const navigate = useNavigate()

  const onButtonClick = () => {
    navigate(nextRoute)
  }

  return (
    <div className={`default-container flex ${genderBasedBackground} flex-col`}>
      <div className="flex h-full w-full flex-col items-center justify-between self-center overflow-auto px-7 pb-10 pt-7 lg:w-5/6 xl:w-5/12">
        <Heading text={t('exercise_routine.headline')} />

        <ButtonWrapper>
          <ChoiceButton
            onClick={() => onButtonClick()}
            value={t('exercise_routine.yes')}
            id="yes"
          />
          <ChoiceButton
            onClick={() => onButtonClick()}
            value={t('exercise_routine.no')}
            id="no"
          />
        </ButtonWrapper>
      </div>
    </div>
  )
}
