import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { ContinueButton } from 'components/ContinueButton'

interface Props {
  nextRoute: string
}

export const FeedbackAging: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()
  const { user } = useUser()

  const onButtonClick = () => {
    navigate(nextRoute)
  }

  const userAgeGroup = () => {
    const currentYear = new Date().getFullYear()
    const userAge = currentYear - Number(user.dateOfBirth)

    if (userAge < 20) {
      return 20
    } else if (userAge > 100) {
      return 90
    } else {
      return Math.floor(userAge / 10) * 10
    }
  }

  const headingText = () => {
    const ageGroup = userAgeGroup()
    const gender = user.gender === 'm' ? 'm' : 'f'
    return t(`aging_question_1.headline_${gender}`, { age: ageGroup })
  }

  return (
    <div className="default-container flex flex-col">
      <div className="flex h-full flex-col self-center overflow-auto px-7 pt-7 lg:w-5/6">
        <div className="flex flex-col gap-y-8 text-center">
          <h1
            className="text-center font-title text-2xl font-bold text-black xl:mt-0 xl:text-center"
            dangerouslySetInnerHTML={{
              __html: headingText(),
            }}
          />
        </div>

        <div className="flex h-1/2 w-full flex-col justify-center space-y-5">
          <div className="flex w-full flex-col self-center rounded-xl bg-white p-8 text-center shadow-feedback lg:w-3/5">
            <p className="font-title text-sm text-ar-green">
              {t(`aging_question_1.option_${user.agingQuestionChoiceId}`)}
            </p>
          </div>

          <p className="self-center text-center font-body text-sm text-ar-dark-gray">
            {t(`feedback_aging.feedback_${user.agingQuestionChoiceId}`)}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </div>
  )
}
