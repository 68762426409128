import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { useKeyPress } from 'hooks/useKeyPress'
import { useAutofocus } from 'hooks/useAutofocus'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import Input from 'components/Input'

interface Props {
  nextRoute: string
}

export const Password: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()
  const { user, setUser } = useUser()
  const [password, setPassword] = useState('')
  const [canContinue, setCanContinue] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const passwordInput = useRef(null)

  const onContinue = () => {
    if (!canContinue) {
      return
    }

    setUser({ ...user, password, hasPassword: true })
    setTimeout(() => {
      navigate(nextRoute)
    }, 100)
  }

  const onClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    setCanContinue(password.length > 5)
  }, [password])

  useAutofocus(passwordInput)

  useKeyPress('ENTER', onContinue)

  return (
    <div className="mx-auto flex flex-col justify-start px-8 pt-9 lg:w-5/6 xl:w-5/12">
      <Heading text={t('password.headline')} textColor="ar-dark-gray" />

      <div className="mt-8">
        <div className="relative flex flex-row">
          <Input
            placeholder={t('password.placeholder')}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            type={showPassword ? 'text' : 'password'}
            id="password_input"
            inputMode="text"
            darkMode={false}
            ref={passwordInput}
          />
          <img
            src={
              showPassword
                ? '/images/show-password.png'
                : '/images/hide-password.png'
            }
            className="absolute right-5 top-1/2 w-7 -translate-y-1/2 transform cursor-pointer"
            alt="show-password"
            onClick={onClickShowPassword}
          />
        </div>
        <span className="p-2 font-body text-sm text-ar-dark-gray text-opacity-80">
          {t('password.password_length')}
        </span>
      </div>

      <ContinueButton onClick={onContinue} disabled={!canContinue} />
    </div>
  )
}
