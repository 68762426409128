import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser, GoalType } from 'context/UserContext'
import { Heading } from 'components/Heading'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { ChoiceButton } from 'components/ChoiceButton'
import { RoutePaths } from 'route-paths'

interface Props {
  nextRoute: string
}

export const GoalSelection: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-goals-selection-male'
      : 'bg-goals-selection-female bg-center'
  const navigate = useNavigate()

  useEffect(() => {
    if (!user.goalTypes) {
      navigate(RoutePaths.Goals, { replace: true })
    }
  }, [user.goalTypes, navigate])

  const onButtonClick = (goal: GoalType) => {
    setUser({ ...user, firstGoal: goal })
    navigate(nextRoute)
  }

  if (!user.goalTypes) {
    return null
  }

  return (
    <div className={`default-container ${genderBasedBackground} flex flex-col`}>
      <div className="flex h-full w-full flex-col items-center justify-between self-center overflow-auto px-7 pb-10 pt-7 lg:w-5/6 xl:w-5/12">
        <Heading text={t('goal_selection.headline')} />

        <ButtonWrapper>
          {user.goalTypes.map((goal) => (
            <ChoiceButton
              onClick={() => onButtonClick(goal)}
              value={goal.display}
              id={goal.id}
              key={goal.key}
            />
          ))}
        </ButtonWrapper>
      </div>
    </div>
  )
}
