import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ContinueButton } from 'components/ContinueButton'
import { ReactComponent as Star } from 'icons/star.svg'
import { useUser } from 'context/UserContext'

interface Props {
  nextRoute: string
}

export const Review: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user } = useUser()
  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-exercise-routine-male bg-center'
      : 'bg-exercise-routine-female bg-center'
  const navigate = useNavigate()

  const onButtonClick = () => {
    navigate(nextRoute)
  }

  return (
    <div className={`default-container ${genderBasedBackground} flex flex-col`}>
      <div className="flex h-[90%] w-full flex-col justify-end self-center overflow-auto px-7 pb-10 pt-7 lg:w-5/6">
        <div className="w-full self-center rounded-xl bg-white px-6 pt-6 pb-9 md:w-5/6 lg:w-5/12">
          <div className="flex items-center space-x-1">
            {[1, 2, 3, 4, 5].map((i) => (
              <Star key={i} width="18" height="18" fill="#10CB7C" />
            ))}
          </div>
          <p className="mt-5 font-body text-base text-ar-dark-gray">
            {t('review.body')}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </div>
  )
}
