import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const FeedbackTenMinutes: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()

  const onButtonClick = () => {
    navigate(nextRoute)
  }

  return (
    <>
      <div className="flex h-2/3 w-full flex-col items-center justify-center px-7 text-center">
        <Heading
          textColor="ar-green"
          text={t('feedback_tenminutes.headline')}
        />

        <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
          {t('feedback_tenminutes.subheadline')}
        </p>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
