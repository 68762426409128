import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUser } from 'context/UserContext'
import useApi from 'hooks/useApi'
import { RoutePaths } from 'route-paths'

interface Props {
  locale: string
}

export const MagicLogin: React.FC<Props> = ({ locale }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const { setUser } = useUser()
  const { magicLogin, getExperiments } = useApi(locale)
  const intervalLength = (duration: number) => Math.round(duration / 4.3)

  useEffect(() => {
    const loginUser = async () => {
      if (!token) {
        navigate(RoutePaths.Login, { replace: true })
        return
      }

      const { user: remoteUser, token: authToken } = await magicLogin({
        token,
      })

      const { experiments } = await getExperiments({
        authToken,
        experiments: process.env.REACT_APP_EXPERIMENTS || '',
      })

      if (authToken && remoteUser.latest_subscription) {
        setUser({
          ...remoteUser,
          authToken,
          hasPassword: true,
          experiments,
          assignments: Object.fromEntries(
            experiments.map((e: any) => [e.name, e.variant]),
          ),
          currency: remoteUser.latest_subscription.currency,
          subscription: {
            renewal_date: remoteUser.latest_subscription.expires_date,
            interval_in_months: intervalLength(
              remoteUser.latest_subscription.duration_in_weeks,
            ),
            expires_date: remoteUser.latest_subscription.expires_date,
            duration_in_weeks: remoteUser.latest_subscription.duration_in_weeks,
          },
        })
      } else if (authToken) {
        setUser({
          ...remoteUser,
          authToken,
          hasPassword: true,
          experiments,
          assignments: Object.fromEntries(
            experiments.map((e: any) => [e.name, e.variant]),
          ),
        })
      }

      navigate(RoutePaths.ThankYou)
    }
    loginUser()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}
