import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isFuture } from 'date-fns'
import { useUser } from 'context/UserContext'
import { usePlan } from 'context/PlanContext'
import { RoutePaths } from 'route-paths'

export const useAutoRedirect = () => {
  const { user } = useUser()
  const { plan } = usePlan()
  const location = useLocation()
  const navigate = useNavigate()

  // skipping all hooks when is magic login page or cancellation request
  const isNotMagicLogin = location.pathname !== RoutePaths.MagicLogin
  const isNotCancellationRequest =
    location.pathname !== RoutePaths.CancellationRequest
  const isUnskippableRoute = [
    RoutePaths.Checkout as string,
    RoutePaths.PasswordReset as string,
    RoutePaths.ForgotPassword as string,
    RoutePaths.GiftCard as string,
  ].includes(location.pathname)

  const isNotMeditationPage = !location.pathname.includes('meditation_pages')

  useEffect(() => {
    if (location.pathname === RoutePaths.Plans) {
      // avoid double page view on plans page
      return
    }

    if (
      !isUnskippableRoute &&
      isNotCancellationRequest &&
      isNotMagicLogin &&
      user.authToken &&
      user.email &&
      !user.subscription
    ) {
      if (plan) {
        navigate(RoutePaths.Checkout, { replace: true })
      } else {
        navigate(RoutePaths.Plans, { replace: true })
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location.pathname === RoutePaths.ThankYou) {
      // avoid double page view on thank you page
      return
    }

    if (
      isNotMagicLogin &&
      isNotCancellationRequest &&
      isNotMeditationPage &&
      user.subscription &&
      isFuture(Date.parse(user.subscription.renewal_date)) &&
      user.hasPassword
    ) {
      navigate(RoutePaths.ThankYou, { replace: true })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
