import React from 'react'

interface Props {
  text: string
  centered?: boolean
}

export const SubHeading: React.FC<Props> = ({ text, centered = false }) => {
  return (
    <p
      className={`${centered ? 'text-center' : ''}
                   font-lighter mt-2 font-body text-sm text-white xl:text-center`}>
      {text}
    </p>
  )
}
