import { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Circle } from 'rc-progress'
import { useTranslation } from 'react-i18next'
import { Heading } from 'components/Heading'
import { ReactComponent as Logo } from 'icons/logo.svg'
import { usePlan } from 'context/PlanContext'
import { useUser } from 'context/UserContext'
import usePlanData from 'hooks/usePlanData'
import { RoutePaths } from 'route-paths'

interface LoadingTextMap {
  [key: number]: string
}

interface Props {
  locale: string
}

export const Loading: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const loadingTexts: LoadingTextMap = useMemo(() => {
    return {
      0: t('loading.progress0'),
      20: t('loading.progress20'),
      38: t('loading.progress38'),
      53: t('loading.progress53'),
      68: t('loading.progress68'),
      73: t('loading.progress73'),
      90: t('loading.progress90'),
    }
  }, [t])

  const { plan } = usePlan()
  const { user, setUser } = useUser()
  const { plans, currency, country } = usePlanData(locale)
  const navigate = useNavigate()
  const [progress, setProgress] = useState(0)
  const [loadingText, setLoadingText] = useState(loadingTexts[0])
  const [redirectPath, setRedirectPath] = useState(RoutePaths.Plans)

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>

    setUser({ ...user, currency, country })

    if (
      redirectPath !== RoutePaths.Checkout &&
      plans &&
      plans?.find((p) => p.code === plan?.code)
    ) {
      setRedirectPath(RoutePaths.Checkout)
    }

    if (progress < 100) {
      interval = setInterval(() => setProgress(progress + 1), 80)
    } else {
      navigate(redirectPath)
    }

    if (loadingTexts[progress]) {
      setLoadingText(loadingTexts[progress])
    }

    return () => clearInterval(interval)
  }, [progress, navigate, loadingTexts, plans]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <header className="grid grid-cols-2 px-8 pt-9">
        <Logo height="30" width="35" fill="black" />
      </header>

      <div className="w-auto px-8 pt-4">
        <Heading text={t('loading.headline')} textColor="ar-dark-gray" />
        <p className="font-body text-sm text-ar-dark-gray xl:text-center">
          {t('loading.subheadline')}
        </p>

        <div className="relative mx-auto mt-28 w-40">
          <div className="absolute top-0 left-0 h-full w-full bg-opacity-0 py-16 text-center font-link text-2xl">
            {progress}%
          </div>
          <Circle
            percent={progress}
            strokeWidth={9}
            strokeColor="#00D186"
            trailWidth={9}
            trailColor="#fff"
          />
          <span className="mt-10 block w-full text-center text-xs text-ar-dark-gray">
            {loadingText}
          </span>
        </div>
      </div>
    </>
  )
}
