import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { ChoiceButton } from 'components/ChoiceButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const Mindstate: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()
  const onButtonClick = () => {
    navigate(nextRoute)
  }

  return (
    <div className="default-container flex flex-col bg-mindstate bg-center">
      <div className="flex h-full flex-col justify-between self-center overflow-auto px-7 pb-10 pt-7 lg:w-5/6 xl:w-5/12">
        <div className="flex flex-col gap-y-2">
          <Heading text={t('mindstate.headline')} />
          <p className="font-body text-sm text-white xl:text-center">
            {t('mindstate.subheadline')}
          </p>
        </div>

        <ButtonWrapper>
          <ChoiceButton
            onClick={onButtonClick}
            value={t('mindstate.option_1')}
            id="option_1"
          />
          <ChoiceButton
            onClick={onButtonClick}
            value={t('mindstate.option_2')}
            id="option_2"
          />
          <ChoiceButton
            onClick={onButtonClick}
            value={t('mindstate.option_3')}
            id="option_3"
          />
        </ButtonWrapper>
      </div>
    </div>
  )
}
