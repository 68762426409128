export enum RoutePaths {
  Age = '/pages/age',
  AgingQuestion1 = '/pages/aging_question_1',
  CancellationRequest = '/cancellation-request',
  CancellationRequestSuccess = '/cancellation-request/success',
  Checkout = '/register/subscriptions/new',
  Diet = '/pages/diet',
  Email = '/pages/email',
  ExerciseRoutine = '/pages/exercise_routine',
  ExerciseTime = '/pages/exercise_time',
  ExperienceYoga = '/pages/experience_yoga',
  FeedbackAging = '/pages/feedback_aging',
  FeedbackExerciseRoutine = '/pages/feedback_exerciseroutine',
  FeedbackExperience = '/pages/feedback_experience',
  FeedbackExperts = '/pages/feedback_experts',
  FeedbackGoalChoice = '/pages/feedback_goalchoice',
  FeedbackGoalWeight = '/pages/feedback_goalweight',
  FeedbackGoalset = '/pages/feedback_goalset',
  FeedbackPersonalize = '/pages/feedback_personalize',
  FeedbackRisks = '/pages/feedback_risks',
  FeedbackTenMinutes = '/pages/feedback_tenminutes',
  FitnessLevel = '/pages/fitness_level',
  ForgotPassword = '/password_resets/new',
  ForgotPasswordSuccess = '/password_resets/success',
  Gender = '/pages/goals',
  GiftCard = '/gift-card',
  GoalSelection = '/pages/goal_selection',
  GoalWeight = '/pages/goal_weight',
  Goals = '/pages/goal',
  Height = '/pages/height',
  HowFeelExercise = '/pages/how_feel_exercise',
  HowOftenExercise = '/pages/how_often_exercise',
  Loading = '/pages/loading',
  Login = '/onboarding/sessions/new',
  MagicLogin = '/magic_login',
  Mindstate = '/pages/mindstate',
  Pace = '/pages/pace',
  Password = '/pages/password',
  PasswordReset = '/password_resets/edit',
  Plans = '/onboarding/plans',
  Review = '/pages/review',
  Root = '/',
  StatementFour = '/pages/statement_4',
  StatementOne = '/pages/statement_1',
  StatementThree = '/pages/statement_3',
  StatementTwo = '/pages/statement_2',
  ThankYou = '/register/subscriptions/success',
  Weight = '/pages/weight',

  /* Meditation funnel */
  MeditationMovement = '/meditation_pages/movement',
  MeditationBalance = '/meditation_pages/balance',
  MeditationMind = '/meditation_pages/mind',
  MeditationSleep = '/meditation_pages/sleep',
  MeditationEnergy = '/meditation_pages/energy',
  MeditationFatigue = '/meditation_pages/fatigue',
  MeditationPlan = '/meditation_pages/plan',
  MeditationThankYou = '/meditation_pages/thank_you',

  /* Aging funnel */
  AgingFunnelSymptoms = '/pages/ag/symptoms',
  AgingFunnelDiet = '/pages/ag/balanced_diet',
  AgingFunnelActivity = '/pages/ag/activity',
  AgingFunnelFeedbackAge = '/pages/ag/feedback_age',
  AgingFunnelFeedbackAgeAge = '/pages/ag/age',
  AgingFunnelDietChoice = '/pages/ag/diet',
  AgingFunnelFeedbackExperts = '/pages/ag/feedback_experts',
  AgingFunnelFeedbackGoalChoice = '/pages/ag/feedback_goalchoice',
  AgingFunnelFeedbackGoalWeight = '/pages/ag/feedback_goalweight',
  AgingFunnelFeedbackGoalset = '/pages/ag/feedback_goalset',
  AgingFunnelFeedbackPersonalize = '/pages/ag/feedback_personalize',
  AgingFunnelFeedbackRisks = '/pages/ag/feedback_risks',
  AgingFunnelFeedbackTenMinutes = '/pages/ag/feedback_tenminutes',
  AgingFunnelFitnessLevel = '/pages/ag/fitness_level',
  AgingFunnelGender = '/pages/ag/goals',
  AgingFunnelGoalSelection = '/pages/ag/goal_selection',
  AgingFunnelGoalWeight = '/pages/ag/goal_weight',
  AgingFunnelGoals = '/pages/ag/goal',
  AgingFunnelHeight = '/pages/ag/height',
  AgingFunnelAge = '/pages/ag/age',
  AgingFunnelStatementOne = '/pages/ag/statement_1',
  AgingFunnelStatementTwo = '/pages/ag/statement_2',
  AgingFunnelStatementThree = '/pages/ag/statement_3',
  AgingFunnelStatementFour = '/pages/ag/statement_4',
  AgingFunnelWeight = '/pages/ag/weight',

  /* Aging funnel V1 */
  AgingFunnelGoalsV1 = '/pages/ag/v1/goal',
  AgingFunnelFeedbackGoalChoiceV1 = '/pages/ag/v1/feedback_goalchoice',
  AgingFunnelStatementOneV1 = '/pages/ag/v1/statement_1',
  AgingFunnelStatementTwoV1 = '/pages/ag/v1/statement_2',
  AgingFunnelStatementThreeV1 = '/pages/ag/v1/statement_3',
  AgingFunnelFeedbackReasonsV1 = '/pages/ag/v1/feedback_reasons',

  /* Aging funnel V2 */
  AgingFunnelFeedbackControl = '/pages/ag/v2/feedback_control',
  AgingFunnelAttitude = '/pages/ag/v2/attitude',
  AgingFunnelFeedbackAttitude = '/pages/ag/v2/feedback_attitude',
  AgingFunnelGoalAge = '/pages/ag/v2/goal_age',
  AgingFunnelFeedbackGoalAge = '/pages/ag/v2/feedback_goal_age',
  AgingFunnelSleepTime = '/pages/ag/v2/sleep',
  AgingFunnelEnergyLevel = '/pages/ag/v2/energy',
  AgingFunnelActivityLevel = '/pages/ag/v2/activity_level',
  AgingFunnelFeedbackActivityLevel = '/pages/ag/v2/feedback_activity_level',
  AgingFunnelSittingTime = '/pages/ag/v2/sitting',
  AgingFunnelPersonalityOptin = '/pages/ag/v2/personality_test',
  AgingFunnelPersonalityTest = '/pages/ag/v2/personality_test_questions',
  AgingFunnelPersonalityOne = '/pages/ag/v2/personality_test_question_1',
  AgingFunnelPersonalityTwo = '/pages/ag/v2/personality_test_question_2',
  AgingFunnelPersonalityThree = '/pages/ag/v2/personality_test_question_3',
  AgingFunnelPersonalityFourth = '/pages/ag/v2/personality_test_question_4',
  AgingFunnelPersonalityFifth = '/pages/ag/v2/personality_test_question_5',
  AgingFunnelFeedbackPersonalityTest = '/pages/ag/v2/feedback_personality_test',
}
