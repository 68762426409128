import paypalImg from './paypal.png'
import cardsImg from './cards.png'

interface Props {}

export const Loading: React.FC<Props> = () => {
  return (
    <div className="m-auto px-8 pt-9 lg:w-5/6 xl:w-7/12">
      <h1 className="text-left font-title text-2xl font-bold text-ar-light-gray">
        <div className="flex w-full animate-pulse">
          <div className="col-span-2 h-5 w-1/2 rounded bg-ar-light-gray"></div>
        </div>
      </h1>
      <div className="mb-28 grid grid-cols-1 sm:grid-flow-row sm:grid-cols-2 sm:gap-6">
        <div className="my-5 flex w-5/6 flex-col space-y-5">
          <h3 className="text-md font-link text-ar-green">
            <div className="flex w-full animate-pulse">
              <div className="col-span-2 h-3 w-1/2 rounded bg-ar-green"></div>
            </div>
          </h3>
          <div className="font-body text-sm">
            <div className="flex w-full animate-pulse">
              <div className="col-span-2 h-2 w-1/2 rounded bg-ar-light-gray"></div>
            </div>
          </div>
        </div>
        <div className="my-5 flex w-5/6 flex-col space-y-5">
          <h3 className="text-md font-link text-ar-green">
            <div className="flex w-full animate-pulse">
              <div className="col-span-2 h-3 w-1/2 rounded bg-ar-green"></div>
            </div>
          </h3>
          <div className="font-body text-sm text-ar-light-gray">
            <div className="flex w-full animate-pulse">
              <div className="col-span-2 h-2 w-1/2 rounded bg-ar-light-gray"></div>
            </div>
          </div>
        </div>
        <div className="sm:row-span-2">
          <div className="rounded-lg p-6 shadow-lg ring-1 ring-ar-white">
            <div className="flex justify-between">
              <div className="flex w-full animate-pulse">
                <div className="col-span-2 h-full w-1/2 rounded bg-ar-light-gray"></div>
              </div>
              <img src={paypalImg} alt="PayPal" height="24" width="34" />
            </div>
          </div>
          <div className="mt-5 rounded-lg p-6 shadow-lg ring-1 ring-ar-white">
            <div className="flex justify-between">
              <div className="flex w-full animate-pulse">
                <div className="col-span-2 h-full w-1/2 rounded bg-ar-light-gray"></div>
              </div>
              <img src={cardsImg} alt="Credit Cards" width="134" height="24" />
            </div>
            <form className="mt-5 block flex flex-col justify-evenly space-y-4">
              <>
                <div className="grid w-11/12 grid-cols-1 space-y-4 sm:grid-cols-2 sm:space-y-0 sm:space-x-6"></div>
                <label className="font-body text-sm text-ar-dark-gray"></label>

                <label className="font-body text-sm text-ar-dark-gray"></label>
                <div className="grid w-11/12 grid-cols-1 space-y-4 sm:grid-cols-2 sm:space-y-0 sm:space-x-6"></div>
              </>
            </form>
          </div>
        </div>

        <div className="mt-5 rounded-lg p-6 pt-1 shadow-lg ring-1 ring-ar-white sm:mt-0">
          <div className="flex flex-col justify-between space-y-5">
            <h2 className="mt-5 text-left font-title text-2xl text-ar-dark-gray">
              <div className="flex w-full animate-pulse">
                <div className="col-span-2 h-5 w-1/2 rounded bg-ar-light-gray"></div>
              </div>
            </h2>

            <div className="mb-5 font-body text-sm text-ar-dark-gray">
              <div className="flex w-full animate-pulse">
                <div className="col-span-2 h-2 w-1/2 rounded bg-ar-light-gray"></div>
              </div>
              <div className="mt-2 flex w-full animate-pulse">
                <div className="col-span-2 h-2 w-1/2 rounded bg-ar-light-gray"></div>
              </div>
            </div>

            <div className="mb-5 font-body text-sm text-ar-dark-gray">
              <div className="flex w-full animate-pulse">
                <div className="col-span-2 h-2 w-1/2 rounded bg-ar-light-gray"></div>
              </div>
              <div className="mt-2 flex w-full animate-pulse">
                <div className="col-span-2 h-2 w-1/2 rounded bg-ar-light-gray"></div>
              </div>
            </div>

            <div className="mb-5 font-body text-sm text-ar-dark-gray">
              <div className="flex w-full animate-pulse">
                <div className="col-span-2 h-2 w-1/2 rounded bg-ar-light-gray"></div>
              </div>
              <div className="mt-2 flex w-full animate-pulse">
                <div className="col-span-2 h-2 w-1/2 rounded bg-ar-light-gray"></div>
              </div>
            </div>
          </div>
          <div className="mt-10 flex w-full animate-pulse">
            <div className="col-span-2 h-3 w-1/2 rounded bg-ar-green"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
