type EventName =
  | 'signup_success'
  | 'initiate_checkout'
  | 'conversion'
  | 'navigation_click'
  | 'meditation-purchase'

interface UserExperimentsProps {
  name: string
  variant: string
  version: number
}

export default function useSnowplow() {
  const EXPERIMENTS_SCHEMA =
    'iglu:com.asanarebel/experiment_context/jsonschema/1-0-0'

  const _snowplowScript = () => {
    ;(function (p: any, l: any, o: any, w: any, i?: any, n?: any, g?: any) {
      if (!p[i]) {
        p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || []
        p.GlobalSnowplowNamespace.push(i)
        p[i] = function () {
          ;(p[i].q = p[i].q || []).push(arguments)
        }
        p[i].q = p[i].q || []
        n = l.createElement(o)
        g = l.getElementsByTagName(o)[0]
        n.async = 1
        n.src = w
        g.parentNode.insertBefore(n, g)
      }
    })(
      window,
      document,
      'script',
      '//cdn.jsdelivr.net/npm/@snowplow/javascript-tracker@3.6.0/dist/sp.min.js',
      'snowplow',
    )
  }

  const _snowplowInit = () => {
    window.snowplow(
      'newTracker',
      'cf',
      process.env.REACT_APP_SNOWPLOW_COLLECTOR_ENDPOINT as string,
      {
        appId: 'ar_web_onboarding',
        platform: 'web',
        post: true,
        discoverRootDomain: true,
        contexts: {
          webPage: true,
          performanceTiming: true,
        },
      },
    )
  }

  const _addGlobalContext = (userExperiments?: UserExperimentsProps[]) => {
    const experimentContexts = (userExperiments || []).map((experiment) => {
      return {
        data: experiment,
        schema: EXPERIMENTS_SCHEMA,
      }
    })

    window.snowplow('addGlobalContexts', experimentContexts)
  }

  const _setUserId = (userId?: string) => {
    window.snowplow('setUserId', userId)
  }

  const _enableLinkTracking = () => {
    if (window.snowplow) {
      window.snowplow('enableLinkClickTracking')
    }
  }

  const snowplowInit = (
    userId?: string,
    userExperiments?: UserExperimentsProps[],
  ) => {
    _snowplowScript()
    _snowplowInit()
    _addGlobalContext(userExperiments)
    _setUserId(userId)
    _enableLinkTracking()
    snowplowTrackPageView() // initial page view
  }

  const snowplowTrackPageView = () => {
    if (window.snowplow) {
      window.snowplow('trackPageView')
    }
  }

  const snowplowTrack = ({
    eventName,
    property,
    value,
    label,
  }: {
    eventName: EventName
    property?: string
    value?: string
    label?: string
  }) => {
    window.snowplow('trackStructEvent', {
      category: 'onboarding',
      action: eventName,
      property: property || '',
      value: value || '',
      label: label || '',
    })
  }

  return {
    snowplowInit,
    snowplowTrackPageView,
    snowplowTrack,
  }
}
